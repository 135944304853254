import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Error from "./AdminPanel/pages/Error/Error";
import AdminRouteApp from "./AdminPanel/Routes/AdminRouteApp";
import "./App.scss";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* protected */}
          <Route exact path="/*" element={<Navigate to={"/admin"} />} />
          <Route exact path="/admin/*" element={<AdminRouteApp />} />
          <Route exact path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
