import { cilGroup, cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCol,
  CFormInput,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React from "react";
import ReactPaginate from "react-paginate";
import bckSvg from "../../../User/assets/svg/backArrow.svg";
import frtSvg from "../../../User/assets/svg/frontArrow.svg";
import "./scss/admin-contact.scss";

const UserInquiries = () => {
  return (
    <>
      <CRow>
        <div className="d-flex">
          <CIcon icon={cilGroup} size="xxl" />
          <h3>User Inquiries</h3>
        </div>
        <CCol sm={12} className="mt-3">
          <h5>Export Data</h5>
        </CCol>
        <div className="admin-topp">
          <input
            placeholdertext="dd/mm/yy"
            dateformat="dd/MM/yyyy"
            type="date"
            className="sellDate admin-sellDate"
            id="datez"
          />
          <input
            placeholdertext="dd/mm/yy"
            dateformat="dd/MM/yyyy"
            type="date"
            className="sellDate admin-sellDate"
            id="dates"
          />
          <CButton
            color="info"
            className={`text-white reload-sell-btn admin-btn`}
          >
            <CIcon icon={cilReload} />
          </CButton>
          <CButton color="info" className={`text-white`} disabled>
            Export Data
          </CButton>
        </div>
        <CCol sm={12} className="mt-4">
          <h5>Search Data</h5>
        </CCol>

        <div className="admin-topp">
          <CCol lg={3} md={4} sm={5}>
            <CFormInput
              type="search"
              id="exampleFormControlInput1"
              placeholder="Search Text"
            />
          </CCol>
        </div>
      </CRow>
      <div className="bg-white mt-3 ps-3 pe-3 pb-1 mb-3">
        <CTable caption="top" responsive>
          <CTableCaption className="fs-3 text-dark">
            User Inquiry List
          </CTableCaption>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">Sr.No.</CTableHeaderCell>
              <CTableHeaderCell scope="col" className="cc-name">
                User Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="cc-name">
                User Code
              </CTableHeaderCell>
              <CTableHeaderCell scope="col">Mobile</CTableHeaderCell>
              <CTableHeaderCell scope="col" className="cc-name">
                Company ID
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="cc-name">
                Company Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="cc-date">
                Date
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {[1, 2, 3, 4, 5, 6].map((item, index) => (
              <CTableRow key={index}>
                <CTableHeaderCell scope="row">{index}</CTableHeaderCell>
                <CTableDataCell>John smith</CTableDataCell>
                <CTableDataCell>ABC546510</CTableDataCell>
                <CTableDataCell>9265112233</CTableDataCell>
                <CTableDataCell>625406b2e14bb9ba86e6ecfa</CTableDataCell>
                <CTableDataCell>ABC Company</CTableDataCell>
                <CTableDataCell>07-06-2022 11:48:23</CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
      </div>
      <div className="myPaginationBottom">
        <span>Total Results : 6</span>
        <ReactPaginate
          previousLabel={
            <>
              <img src={bckSvg} alt="backButton" />
            </>
          }
          nextLabel={
            <>
              <img src={frtSvg} alt="frtButton" />
            </>
          }
          breakLabel={"..."}
          pageCount={7}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={() => {}}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
};

export default UserInquiries;
