import { cilChartLine } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCol,
  CFormInput,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import bckSvg from "../../../User/assets/svg/backArrow.svg";
import frtSvg from "../../../User/assets/svg/frontArrow.svg";
import "./scss/trending-stocks.scss";
import adminAxiosInstance from "../../../config/index";
import moment from "moment";
import err404 from "../../../errors/svg/noData.jpg";
import err500 from "../../../errors/svg/err500.svg";

const TrendingStocks = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [error, setError] = useState(200);
  let limit = 10;

  const fetchData = async (currentPage, searchData) => {
    setLoading(true);
    try {
      const response = await adminAxiosInstance.get(
        `/watchList/get?page=${currentPage}&limit=${limit}&str=${
          searchData ? searchData : ""
        }`
      );
      console.log("api--->respons---->", response);
      response?.data?.data.result?.forEach((element) => {
        element.momentDate = moment(element.createdAt).format(
          "DD-MM-YYYY h:mm:ss"
        );
      });
      if (response.status === 200) {
        setData(response?.data?.data);
        let totalCount = parseInt(response?.data?.data?.total_count);
        setPageCount(Math.ceil(totalCount / limit));
        setError(200);
      }
      setLoading(false);
    } catch (error) {
      setData();
      console.log("error-----======>", error);
      setLoading(false);
      setError(404);
    }
  };
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
    fetchData(data.selected + 1, "");
  };
  console.log("data----==>", data);

  const pageData = async (currentPage) => {
    fetchData(currentPage, searchData);
  };

  useEffect(() => {
    fetchData(1, searchData);
  }, [searchData]);

  useEffect(() => {
    fetchData(currentPage, "");
  }, []);
  useEffect(() => {
    pageData(currentPage);
  }, [currentPage]);
  return (
    <>
      <div className="d-flex">
        <CIcon icon={cilChartLine} size="xxl" />
        <h3>Watchlist</h3>
      </div>
      <CRow>
        <CCol sm={12} className="mt-4">
          <h5>Search Data</h5>
        </CCol>
        <div className="admin-topp">
          <CCol lg={3} md={4} sm={5}>
            <CFormInput
              type="search"
              id="exampleFormControlInput1"
              placeholder="Search Text"
              onChange={(e) => setSearchData(e.target.value)}
            />
          </CCol>

          {data ? (
            <a
              target="_blank"
              href={
                searchData
                  ? `https://api.fracassets.com/v1/admin/watchList/exportData?str=${searchData}`
                  : "https://api.fracassets.com/v1/admin/watchList/exportData"
              }
              download
              rel="noreferrer"
            >
              <CButton color="info" className={`text-white `}>
                Export Data
              </CButton>
            </a>
          ) : (
            <CButton color="info" className={`text-white`} disabled>
              Export Data
            </CButton>
          )}
        </div>
      </CRow>
      {loading ? (
        <div className="loader-content">
          <div className="spinner-border text-secondary"></div>
        </div>
      ) : (
        <div className="bg-white mt-3 ps-3 pe-3 pb-1 mb-3">
          <CTable caption="top" responsive>
            {error === 200 ? (
              <>
                <CTableCaption className="fs-3 text-dark">
                  Watchlist Stocks
                </CTableCaption>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">Sr.No.</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Logo</CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      Company Name
                    </CTableHeaderCell>

                    <CTableHeaderCell scope="col">Total Users</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Url Name</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {data?.data?.companyData?.map((item, index) => (
                    <CTableRow key={index}>
                      <CTableHeaderCell scope="col">
                        {index + 1}
                      </CTableHeaderCell>
                      <CTableDataCell scope="col">
                        <img
                          src={item?.logo ? item?.logo : "-"}
                          alt="logo"
                          style={{ width: "100px", height: "80px" }}
                        />
                      </CTableDataCell>
                      <CTableDataCell scope="col">
                        {item?.name ? item?.name : "-"}
                      </CTableDataCell>

                      <CTableDataCell scope="col">
                        {item?.totalUsers ? item?.totalUsers : "-"}
                      </CTableDataCell>
                      <CTableDataCell scope="col">
                        {item?.urlName ? item?.urlName : "-"}
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                  {data?.data?.propertyData?.map((item, index) => (
                    <CTableRow key={index}>
                      <CTableHeaderCell scope="col">
                        {index + 1}
                      </CTableHeaderCell>
                      <CTableDataCell scope="col">
                        <img
                          src={item?.featureImage ? item?.featureImage : "-"}
                          alt="logo"
                          style={{ width: "100px", height: "80px" }}
                        />
                      </CTableDataCell>
                      <CTableDataCell scope="col">
                        {item?.name ? item?.name : "-"}
                      </CTableDataCell>
                      <CTableDataCell scope="col">
                        {item?.totalUsers ? item?.totalUsers : "-"}
                      </CTableDataCell>
                      <CTableDataCell scope="col">
                        {item?.urlName ? item?.urlName : "-"}
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </>
            ) : error === 404 ? (
              <CTableBody>
                <CTableRow>
                  <CTableDataCell className="d-flex justify-content-center">
                    <img
                      src={err404}
                      alt=""
                      style={{ height: "400px", width: "400px" }}
                    />
                  </CTableDataCell>
                </CTableRow>
              </CTableBody>
            ) : (
              <CTableBody>
                <CTableRow>
                  <CTableDataCell className="d-flex justify-content-center">
                    <img
                      src={err500}
                      alt=""
                      style={{ height: "400px", width: "400px" }}
                    />
                  </CTableDataCell>
                </CTableRow>
              </CTableBody>
            )}
          </CTable>
        </div>
      )}
      {error === 200 ? (
        pageCount && pageCount > 1 ? (
          <div className="myPaginationBottom">
            <span>Total Result : {data?.total_count}</span>
            <ReactPaginate
              previousLabel={
                <>
                  <img src={bckSvg} alt="backButton" />
                </>
              }
              nextLabel={
                <>
                  <img src={frtSvg} alt="frtButton" />
                </>
              }
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={{ handlePageClick }}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </>
  );
};

export default TrendingStocks;
