import { cilGroup } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CCol, CRow } from "@coreui/react";
// import { number } from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import adminAxiosInstance, { imageAxiosInstance } from "../../../config";
import errorImage from "../../assets/errorImage.jpg";
import { EditAdminSchema } from "./adminEditSchema";

const AdminEdit = () => {
  const [url, setUrl] = useState("");
  const [data, setData] = useState();
  // const [imageLoading, setImageLoading] = useState(false);
  const adminToken = localStorage.getItem("token");

  const fetchData = async () => {
    try {
      const response = await adminAxiosInstance.get("/profile/get", {
        headers: { Authorization: `Bearer ${adminToken}` },
      });
      // console.log("response==>", response);
    } catch (error) {
      // console.log("error", error);
    }
  };
  const uploadImg = async (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      // setTenantLoading(true);
      var formdata = new FormData();
      formdata.append("multi-files", file);
      try {
        const response = await imageAxiosInstance.post("/upload", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setUrl(response?.data?.data[0]?.mediaLink);
        // setTenantLoading(false);
      } catch (error) {
        // setTenantLoading(false);
        // console.log("error====>", error);
      }
    }
  };
  const handleFormSubmit = async (values) => {
    values.profileImage = url ? url : data?.profileImage;

    try {
      const response = await adminAxiosInstance.put(
        "/profile/update",
        {
          ...values,
        },
        {
          headers: { Authorization: `Bearer ${adminToken}` },
        }
      );
    } catch (error) {
      // console.log("error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="d-flex">
        <CIcon icon={cilGroup} size="xxl" />
        <h3>Edit Admin Profile</h3>
      </div>
      <div>
        <Link className="text-decoration-none text-white" to="/admin/dashboard">
          <button className="text-white btn btn-info">Go Back</button>
        </Link>
      </div>

      <Formik
        enableReinitialize={true}
        validationSchema={EditAdminSchema}
        initialValues={{
          fullName: data?.fullName || "",
          mobile: data?.mobile || " ",
          // email: data?.email || "",
          profileImage: "",
          img: "",
        }}
        onSubmit={handleFormSubmit}
      >
        {({ values, isSubmitting, handleChange, handleBlur }) => (
          <Form>
            <div>
              <div className="border-top border-info border-3 bg-white mt-3 p-2">
                <h4 className="fw-normal">Enter Profile Details</h4>
                <CRow>
                  <CCol sm={6}>
                    <div>
                      <div className="form-group mt-3">
                        <label htmlFor="fname" className="fw-bold mb-2">
                          Full Name
                        </label>
                        <Field
                          className="form-control"
                          name="fullName"
                          placeholder="Full Name."
                          id="fname"
                        />
                        <div className="text-danger">
                          <ErrorMessage name="fullName" />
                        </div>
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="lname" className="fw-bold mb-2">
                          Mobile No.
                        </label>
                        <Field
                          className="form-control"
                          name="mobile"
                          placeholder="Mobile No."
                          id="lname"
                        />
                        <div className="text-danger">
                          <ErrorMessage name="mobile" />
                        </div>
                      </div>
                      {/* <div className="form-group mt-4">
                        <label htmlFor="pnum" className="fw-bold mb-2">
                          Email
                        </label>
                        <Field
                          className="form-control"
                          id="pnum"
                          name="email"
                          placeholder="Email."
                          rows={6}
                        />
                        <div className="text-danger">
                          <ErrorMessage name="email" />
                        </div>
                      </div> */}
                    </div>
                  </CCol>
                  <CCol sm={6} className="mt-3">
                    <div className="text-center">
                      <img
                        draggable="false"
                        className="img-thumbnail max_img_view_admin"
                        src={url || errorImage}
                        alt="default image"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="img" className="fw-bold mb-2">
                        Edit Your profile Image
                      </label>
                      <Field
                        type="file"
                        id="img"
                        name="img"
                        value={values.img}
                        className="form-control"
                        onChange={(e) => {
                          uploadImg(e);
                          handleChange(e);
                        }}
                        onFocus={handleBlur}
                        accept="image/*"
                      />
                    </div>
                  </CCol>
                  <CCol>
                    <div>
                      <CButton
                        type="submit"
                        className="btn btn-info text-white mt-3"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Please wait..." : "Submit"}
                      </CButton>
                    </div>
                  </CCol>
                </CRow>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AdminEdit;
