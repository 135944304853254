import react from "react";
import NoteContext from "./noteContext";
import { useState } from "react";
import adminAxiosInstance from "../../../config";
import { useEffect } from "react";

const NoteState = (props) =>{
    const [roleData, setRoleData] = useState([]);
    const adminToken = localStorage.getItem("token");
  
    const checkPermissions = async () => {
      try {
        const response = await adminAxiosInstance.get(`/auth/checkPermissions`, {
          headers: { Authorization: `Bearer ${adminToken}` },
        });
        const accessData = response.data.data?.adminRoles?.Access || [];
        setRoleData(accessData);
      } catch (error) {
        // Handle error
      }
    };
  
    useEffect(() => {
      checkPermissions();
    }, []);
    return (
    <>
    <NoteContext.Provider value={roleData}>
        {props.children}

    </NoteContext.Provider>
    </>
    )
}

export default NoteState;