import { CCol, CContainer, CRow } from "@coreui/react";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AdminLoginSchema } from "../../common/Validation";
import LogInSvg from "../assets/svg/LogIn.svg";
import "./scss/login.scss";
import adminAxiosInstance from "../../config/index";
import SweetAlert from "../../helper/SweetAlert";

const decrypt = () => {};
const Login = () => {
  const navigate = useNavigate();
  const isLoggedIn = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/admin");
    }
  };
  const handleFormSubmit = async (values, { setSubmitting }) => {
    var token;
    try {
      const response = await adminAxiosInstance.post("/auth/login", {
        ...values,
      });
      token = response?.data?.data;
      localStorage.setItem("token", token);
    } catch (error) {
      SweetAlert({
        icon: "error",
        text: error?.response?.data?.message,
        title: "Warning!",
      });
    }
    if (token) {
      navigate("/admin");
    }
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <>
      <CContainer className="container-custom">
        <div className="d-flex justify-content-center align-items-center center-form">
          <CRow className="bg-white rounded p-3 inner-form shadow">
            <CCol
              className="text-center text-white fw-bolder py-3 bg-secondary"
              xl={12}
            >
              LogIn
            </CCol>
            <CCol
              xl={6}
              className="d-flex justify-content-center align-items-center form-fields"
            >
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={AdminLoginSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  isSubmitting,
                  handleBlur,
                  touched,
                  handleSubmit,
                }) => (
                  <Form>
                    <div className="form-group mb-3">
                      <label htmlFor="mobile">Email</label>
                      <input
                        type="text"
                        id="email"
                        placeholder="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        className={`form-control ${
                          errors.email && touched.email ? " is-invalid" : ""
                        }`}
                      />
                      <div className="text-danger">
                        <ErrorMessage name="mobile" />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        // className="form-control"
                        id="password"
                        placeholder="Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`form-control ${
                          errors.password && touched.password
                            ? " is-invalid"
                            : ""
                        }`}
                      />
                      <div className="text-danger">
                        <ErrorMessage name="password" />
                      </div>
                    </div>
                    <button
                      className="btn btn-primary fw-bolder"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Please wait..." : "Submit"}
                    </button>
                  </Form>
                )}
              </Formik>
            </CCol>
            <CCol xl={6}>
              <div className="h-100 d-flex justify-content-center align-items-center">
                <img
                  src={LogInSvg}
                  className="img-fluid mt-1 resp-img"
                  alt="login"
                />
              </div>
            </CCol>
          </CRow>
        </div>
      </CContainer>
    </>
  );
};

export default Login;
