import {
  cibCircleci,
  cibMarketo,
  cibSellfy,
  cibStadia,
  cibWhenIWork,
  cilBook,
  cilBuilding,
  cilCircle,
  cilCommand,
  cilContact,
  cilFax,
  cilFile,
  cilGlobeAlt,
  cilGroup,
  cilHeader,
  cilHome,
  cilNewspaper,
  cilSpeedometer,
  cilUser,
  cilCreditCard,
  cibAddthis,
  cibQualcomm,
  cilReportSlash,
  cilPaperPlane,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CNavGroup, CNavItem } from "@coreui/react";
import React from "react";

const nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/admin/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Form Data",
    to: "/admin/formdata",
    icon: <CIcon icon={cilFile} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Buy",
        to: "/admin/formdata/buy",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
      // {
      //   component: CNavItem,
      //   name: "Sell",
      //   to: "/admin/formdata/sell",
      //   icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      // },
      {
        component: CNavItem,
        name: "Download",
        to: "/admin/formdata/download",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Watchlist",
        to: "/admin/formdata/watchlist",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Contact",
        to: "/admin/formdata/contact",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
      // {
      //   component: CNavGroup,
      //   name: "Inquiries",
      //   to: "/admin/formdata/inquiry",
      //   icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      //   items: [
      //     {
      //       component: CNavItem,
      //       name: "User Inquiries",
      //       to: "/admin/formdata/user-inquiry",
      //       icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      //     },
      //     // {
      //     //   component: CNavItem,
      //     //   name: "CP Inquiries",
      //     //   to: "/admin/formdata/cp-inquiry",
      //     //   icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      //     // },
      //   ],
      // },
      // {
      //   component: CNavItem,
      //   name: "Partner",
      //   to: "/admin/formdata/partner",
      //   icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      // },
      {
        component: CNavItem,
        name: "Job Application",
        to: "/admin/formdata/job-application",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
      // {
      //   component: CNavItem,
      //   name: "NewsLetter",
      //   to: "/admin/formdata/newsletter",
      //   icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      // },
    ],
  },
  {
    component: CNavGroup,
    name: "Equity And Real Estate",
    to: "/admin/equity-crowdfunding",
    icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Equity",
        to: "/admin/equity-crowdfunding/equities",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Real Estate",
        to: "/admin/equity-crowdfunding/real-estate",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
    ],
  },
  // {
  //   component: CNavItem,
  //   name: "DRHP Filed Companies",
  //   to: "/admin/upcoming-ipos",
  //   icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: "Blog",
    to: "/admin/blog",
    icon: <CIcon icon={cilBook} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "In The News",
    to: "/admin/formdata/inthenews",
    icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Staff",
    to: "/admin/staff",
    icon: <CIcon icon={cibStadia} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "User Management",
    to: "/admin/userlist",
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "User",
        to: "/admin/userlist",
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      },

      {
        component: CNavItem,
        name: "Support",
        to: "/admin/usersupport",
        icon: <CIcon icon={cibWhenIWork} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Otp",
        to: "/admin/otplist",
        icon: <CIcon icon={cibWhenIWork} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Cp Management",
    to: "/admin/cplist",
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Cp List",
        to: "/admin/cplist",
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      },
      // {
      //   component: CNavItem,
      //   name: "Cp Support",
      //   to: "/admin/cpsupport",
      //   icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      // },
      {
        component: CNavItem,
        name: "Cp Kyc",
        to: "/admin/cpkyc",
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Chart Management",
    to: "/admin/chartlist",
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Chart List",
        to: "/admin/chartlist",
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      },
      // {
      //   component: CNavItem,
      //   name: "Cp Support",
      //   to: "/admin/cpsupport",
      //   icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      // },
      // {
      //   component: CNavItem,
      //   name: "Cp Kyc",
      //   to: "/admin/cpkyc",
      //   icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      // },
    ],
  },
  {
    component: CNavGroup,
    name: "Transactions",
    to: "/admin/transactions",
    icon: <CIcon icon={cilCreditCard} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "By Product",
        to: "/admin/by-product",
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      },

      {
        component: CNavItem,
        name: "By Wallet",
        to: "/admin/by-wallet",
        icon: <CIcon icon={cibWhenIWork} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Return",
        to: "/admin/return",
        icon: <CIcon icon={cibWhenIWork} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Document",
    to: "/admin/document",
    icon: <CIcon icon={cilCreditCard} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "document",
        to: "/admin/document",
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      },
    ],
  },

  {
    component: CNavGroup,
    name: "Header",
    to: "/admin/cplist",
    icon: <CIcon icon={cilHeader} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Global Script Title",
        to: "/admin/header/globalScriptTitle",
        icon: <CIcon icon={cilGlobeAlt} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Home",
        to: "/admin/header/home",
        icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "About Us",
        to: "/admin/header/aboutUs",
        icon: <CIcon icon={cibCircleci} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Real Estate",
        to: "/admin/header/realEstate",
        icon: <CIcon icon={cibWhenIWork} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Equity",
        to: "/admin/header/equity",
        icon: <CIcon icon={cibMarketo} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "How it works",
        to: "/admin/header/howItWorks",
        icon: <CIcon icon={cibMarketo} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Insights",
        to: "/admin/header/insights",
        icon: <CIcon icon={cibMarketo} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "FAQs",
        to: "/admin/header/faqs",
        icon: <CIcon icon={cilFax} customClassName="nav-icon" />,
      },
      // {
      //   component: CNavItem,
      //   name: "Indian Companies",
      //   to: "/admin/header/IndianCompany",
      //   icon: <CIcon icon={cilCommand} customClassName="nav-icon" />,
      // },
      // {
      //   component: CNavItem,
      //   name: "International Companies",
      //   to: "/admin/header/InternationalCompany",
      //   icon: <CIcon icon={cibIntercom} customClassName="nav-icon" />,
      // },
      // {
      //   component: CNavItem,
      //   name: "Sell",
      //   to: "/admin/header/sell",
      //   icon: <CIcon icon={cibSellfy} customClassName="nav-icon" />,
      // },
      // {
      //   component: CNavItem,
      //   name: "Upcoming IPOs",
      //   to: "/admin/header/ipo",
      //   icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      // },
      {
        component: CNavItem,
        name: "Contact Us",
        to: "/admin/header/contactUs",
        icon: <CIcon icon={cilContact} customClassName="nav-icon" />,
      },

      // {
      //   component: CNavItem,
      //   name: "In The News",
      //   to: "/admin/header/inTheNews",
      //   icon: <CIcon icon={cilNewspaper} customClassName="nav-icon" />,
      // },
      // {
      //   component: CNavItem,
      //   name: "Be Our Partner",
      //   to: "/admin/header/beOurPartner",
      //   icon: <CIcon icon={cibPalantir} customClassName="nav-icon" />,
      // },
      // {
      //   component: CNavItem,
      //   name: "Career",
      //   to: "/admin/header/career",
      //   icon: <CIcon icon={cibWhenIWork} customClassName="nav-icon" />,
      // },
      // {
      //   component: CNavItem,
      //   name: "",
      //   to: "/admin/header/",
      //   icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
      // },
    ],
  },
  {
    component: CNavItem,
    name: "Roles",
    to: "/admin/roles",
    icon: <CIcon icon={cibAddthis} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Employee Management",
    to: "/admin/employeemanagement",
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
  },
];

export default nav;
