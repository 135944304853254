import { cilDescription, cilGroup, cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCol,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import bckSvg from "../../../User/assets/svg/backArrow.svg";
import frtSvg from "../../../User/assets/svg/frontArrow.svg";
import "./scss/assets-approve.scss";

const AssetsApprove = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className="d-flex">
        <CIcon icon={cilGroup} size="xxl" />
        <h3> Assets Approve</h3>
      </div>
      <div className="d-flex justify-content-between mt-2"></div>
      <CRow>
        <CCol sm={12} className="mt-3">
          <h5>Export Data</h5>
        </CCol>
        <div className="admin-topp">
          {/* <CCol lg={2} md={6} sm={5} className="mb-3"> */}
          <input
            placeholdertext="dd/mm/yy"
            dateformat="dd/MM/yyyy"
            type="date"
            className="sellDate admin-sellDate"
            id="datez"
          />
          <input
            placeholdertext="dd/mm/yy"
            dateformat="dd/MM/yyyy"
            type="date"
            className="sellDate admin-sellDate"
            id="dates"
          />
          <CButton
            color="info"
            className={`text-white reload-sell-btn  admin-btn`}
          >
            <CIcon icon={cilReload} />
          </CButton>
          <CButton color="info" className={`text-white`} disabled>
            Submit
          </CButton>
          <CButton color="info" className={`text-white ms-3`} disabled>
            Export Data
          </CButton>
        </div>

        <CCol sm={12} className="mt-4">
          <h5>Search Data</h5>
        </CCol>

        <div className="admin-topp">
          <CCol lg={3} md={4} sm={5}>
            <CFormInput
              type="search"
              id="exampleFormControlInput1"
              placeholder="Search Text"
            />
          </CCol>
        </div>
      </CRow>
      <CCol>
        <CRow className="bg-white border-top border-info border-3 mt-2"></CRow>
      </CCol>
      <>
        <div className="bg-white mt-3 ps-3 pe-3 pb-1 mb-3">
          <CTable caption="top" responsive>
            <CTableCaption className="fs-3 text-dark">
              Channel Partner Management List
            </CTableCaption>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">Sr.No.</CTableHeaderCell>
                <CTableHeaderCell scope="col">CP ID</CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-cp-name">
                  CP Name
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-cp-email">
                  CP Email
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-cp-mobile">
                  CP Mobile No.
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-cp-id">
                  Client ID
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-client-name">
                  Client Name
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-client-email">
                  Client Email
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-client-mobile">
                  Client Mobile No.
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">Quantity</CTableHeaderCell>
                <CTableHeaderCell scope="col">Rate</CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-cp-stampD">
                  Stamp Duty
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-cp-total">
                  Total Investment
                </CTableHeaderCell>

                <CTableHeaderCell scope="col" className="ad-sector">
                  Sector
                </CTableHeaderCell>

                <CTableHeaderCell scope="col" className="ad-payment-date">
                  Payment Date
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-payment-mode">
                  Mode of Payment
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                  Nature of Payment
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-ref-id">
                  Reference ID
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">GST Applied</CTableHeaderCell>
                <CTableHeaderCell scope="col">TDS Applied</CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-client-cml">
                  Client CML
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-client-pan">
                  Client PanCard
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-client-cheque">
                  Client Cancel Cheque
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-gst">
                  Client GST Certificate
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-client-gst">
                  Client GST invoice
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                  Broker Commission Per Share
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                  Broker Commission Amount
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">Broker TDS</CTableHeaderCell>
                <CTableHeaderCell scope="col">
                  Net Broker Commission Amount
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">Admin Approved</CTableHeaderCell>
                <CTableHeaderCell scope="col" className="ad-date">
                  Date
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {[1, 2, 3, 4, 5].map((item, index) => (
                <CTableRow key={index}>
                  <CTableHeaderCell scope="row">{index}</CTableHeaderCell>
                  <CTableDataCell>S23412</CTableDataCell>
                  <CTableDataCell>John smith</CTableDataCell>
                  <CTableDataCell>contact@gmail.com</CTableDataCell>
                  <CTableDataCell>9023112233</CTableDataCell>
                  <CTableDataCell>WOEU239479</CTableDataCell>
                  <CTableDataCell>william devops</CTableDataCell>
                  <CTableDataCell>client@gmail.com</CTableDataCell>
                  <CTableDataCell>9592112233</CTableDataCell>
                  <CTableDataCell>5</CTableDataCell>
                  <CTableDataCell>15000</CTableDataCell>
                  <CTableDataCell>27</CTableDataCell>
                  <CTableDataCell> 30000</CTableDataCell>
                  <CTableDataCell>12 jun</CTableDataCell>
                  <CTableDataCell> - </CTableDataCell>
                  <CTableDataCell>
                    {[1, 2].map((list, i) => (
                      <ul>
                        <li> {i}</li>
                      </ul>
                    ))}
                  </CTableDataCell>
                  <CTableDataCell>Yes</CTableDataCell>
                  <CTableDataCell>No</CTableDataCell>
                  <CTableDataCell>
                    <CButton className="text-white" color="info">
                      <CIcon icon={cilDescription} size="sm" />
                    </CButton>
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButton className="text-white" color="info">
                      <CIcon icon={cilDescription} size="sm" />
                    </CButton>
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButton className="text-white" color="info">
                      <CIcon icon={cilDescription} size="sm" />
                    </CButton>
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButton className="text-white" color="info">
                      <CIcon icon={cilDescription} size="sm" />
                    </CButton>
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButton className="text-white" color="info">
                      <CIcon icon={cilDescription} size="sm" />
                    </CButton>
                  </CTableDataCell>
                  <CTableDataCell>
                    {[1, 2].map((list, i) => (
                      <div>
                        <CButton className="text-white mb-3" color="info">
                          <CIcon icon={cilDescription} size="sm" />
                        </CButton>
                      </div>
                    ))}
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButton className="text-white" color="info">
                      <CIcon icon={cilDescription} size="sm" />
                    </CButton>
                  </CTableDataCell>
                  <CTableDataCell>12%</CTableDataCell>
                  <CTableDataCell>500</CTableDataCell>
                  <CTableDataCell>3%</CTableDataCell>
                  <CTableDataCell>53</CTableDataCell>
                  <CTableDataCell>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        adminApproved: "",
                      }}
                    >
                      {({ values, handleChange }) => (
                        <Form>
                          <select
                            value={"pending"}
                            name="adminApproved"
                            id="adminApproved"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          >
                            <option value={false}>Pending</option>
                            <option value={true}>Completed</option>
                          </select>
                        </Form>
                      )}
                    </Formik>
                  </CTableDataCell>
                  <CTableDataCell> 15 jun 2020</CTableDataCell>
                </CTableRow>
              ))}
              {/* Confirm Delete Modal */}
              <CModal
                alignment="center"
                visible={visible}
                onClose={() => setVisible(false)}
              >
                <CModalHeader>
                  <CModalTitle>Confirm Deletion</CModalTitle>
                </CModalHeader>
                <CModalBody>
                  Are you sure you want to delete this item?
                </CModalBody>
                <CModalFooter>
                  <CButton color="secondary" className="text-white">
                    Close
                  </CButton>
                  <CButton color="danger" className="text-white">
                    Delete
                  </CButton>
                </CModalFooter>
              </CModal>
            </CTableBody>
          </CTable>
        </div>
        <div className="myPaginationBottom">
          <span>Total Result : 12</span>
          <ReactPaginate
            previousLabel={
              <>
                <img src={bckSvg} alt="backButton" />
              </>
            }
            nextLabel={
              <>
                <img src={frtSvg} alt="frtButton" />
              </>
            }
            breakLabel={"..."}
            pageCount={3}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={() => {}}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </>
    </>
  );
};

export default AssetsApprove;
