import React from "react";
import swal from "sweetalert2";

const SweetAlert = ({ ...props }) => {
  return swal.fire({
    icon: props.icon,
    title: props.title,
    text: props.text,
    timer: props.timer,
  });
};
export default SweetAlert;
