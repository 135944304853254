import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "400px",
  height: "400px",
};

const center = {
  lat: 21.1702,
  lng: 72.8311,
};

function MyComponent({ lat, lng, getCoordinates }) {
  console.log("lat lng from google map components ", lat, lng);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    // googleMapsApiKey: "AIzaSyCvb8utiVbstiX15tHHcqFp1J9XNUK-MMw",
    googleMapsApiKey: "AIzaSyDS3p7RfJNyYs2FOtY7-I8WXbafUfEL3o4",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({
      lat: lat || 21.1702,
      lng: lng || 72.8311,
    });
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat: lat, lng: lng }}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={getCoordinates}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
