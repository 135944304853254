import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { cilGroup, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CCol, CFormSelect, CRow } from "@coreui/react";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import adminAxiosInstance, { imageAxiosInstance } from "../../../config";
import { ErrorImage } from "../../assets";
import SweetAlert from "../../../helper/SweetAlert";
import ErrorImg from "../../assets/errorimg.png";
import "./scss/Equities.scss";
import GoogleMap from "../components/GoogleMap";
import axios from "axios";
import { addRealEstateSchema } from "../../../common/Validation";
// import "./scss/Equities";
const RealEstateManagment = () => {
  const navigate = useNavigate();
  const [url, setUrl] = useState(ErrorImage);
  const [imageData, setImageData] = useState();
  const [tenantLogo, setTenantLogo] = useState();
  const [tenantLoading, setTenantLoading] = useState(false);
  const [tenantUrl, setTenantUrl] = useState(ErrorImage);
  const [fileArr, setFileArr] = useState([]);
  const [addGallary, setAddGallary] = useState([1]);
  const [loading, setLoading] = useState(false);
  // const [floorFillOne, setFloorFillOne] = useState([]);
  const [floorFillTow, setFloorFillTow] = useState([]);
  const [leaseTeemImage, setLaseTeemImage] = useState("");
  const [amenitiesImage, setAmenitiesImage] = useState([]);
  const [propertyMetaInfoImage, setPropertyMetaInfoImage] = useState([]);
  const [propertyManagerImage, setPropertyManagerImage] = useState();
  const [documentsLink, setDocumentsLink] = useState([]);
  const [geoLoc, setGeoLoc] = useState("");
  const [geoArr, setGeoArr] = useState([]);
  const [geoCordLat, setGeoCordLat] = useState(21.1702401);
  const [geoCordLng, setGeoCordLng] = useState(72.83106070000001);
  // const [que, setQue] = useState();
  // const [ans, setAns] = useState();
  // const [faqArr, setFaqArr] = useState([]);
  // let { id } = useParams();
  let [gallaryImg, setGallaryImg] = useState([]);

  let API_KEY = "AIzaSyDS3p7RfJNyYs2FOtY7-I8WXbafUfEL3o4";

  let boundArrayHelpers;
  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  let boundArrayHelpers2;
  const bindArrayHelpers2 = (arrayHelpers2) => {
    boundArrayHelpers2 = arrayHelpers2;
  };
  let boundArrayHelpers3;
  const bindArrayHelpers3 = (arrayHelpers3) => {
    boundArrayHelpers3 = arrayHelpers3;
  };
  let boundArrayHelpers4;
  const bindArrayHelpers4 = (arrayHelpers4) => {
    boundArrayHelpers4 = arrayHelpers4;
  };
  const gallaryImgFun = async (e, index) => {
    let file = e.target.files[0];
    console.log("file====>", file);
    if (file !== undefined) {
      // console.log("dbdhcbdcdbhcbdhcbh0", file);
      // setTenantLoading(true);
      var formdata = new FormData();
      formdata.append("multi-files", file);
      console.log("formdata===>", formdata);
      try {
        const response = await imageAxiosInstance.post("/upload", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("respons=====>", response);
        // setLaseTeemImage(response.data?.data[0]?.mediaLink);
        let newArr = [...gallaryImg];
        newArr[index] = response?.data?.data[0]?.mediaLink;

        setGallaryImg(newArr);
        // setGallaryImg([...gallaryImg, response?.data?.data[0]?.mediaLink]);
        // setTenantLoading(false);
      } catch (error) {
        // setTenantLoading(false);
        console.log("error====>", error);
      }
    }
  };

  // const uploadPdf = async (e) => {
  //   let file = e.target.files[0];
  //   console.log("file====>", file);
  //   if (file !== undefined) {
  //     console.log("dbdhcbdcdbhcbdhcbh0", file);
  //     // setLoading(true);
  //     var formdata = new FormData();
  //     formdata.append("multi-files", file);
  //     console.log("formdata===>", formdata);
  //     try {
  //       const response = await imageAxiosInstance.post("/upload", formdata, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       });
  //       console.log("respons=====dede==>", response);
  //       setFileArr([...fileArr, response?.data?.data[0]?.mediaLink]);
  //       // setLoading(false);
  //     } catch (error) {
  //       // setLoading(false);
  //       console.log("error====>", error);
  //     }
  //   }
  // };
  console.log("fillarr===>", fileArr);
  const addGallaryImages = (i) => {
    const dataIndex = i + 1;
    setAddGallary([...addGallary, dataIndex]);
  };
  const deleteGallary = (i) => {
    const newArr = [...addGallary];
    newArr.splice(i, 1);
    setAddGallary(newArr);

    const abc = [...gallaryImg];
    abc.splice(i, 1);
    setGallaryImg(abc);
  };

  const deleteFile = (index) => {
    const newArr = [...fileArr];
    newArr.splice(index, 1);
    setFileArr(newArr);
  };

  const uploadFile = async (e) => {
    let file = e.target.files[0];
    console.log("file====>", file);
    if (file !== undefined) {
      console.log("dbdhcbdcdbhcbdhcbh0", file);
      setLoading(true);
      var formdata = new FormData();
      formdata.append("multi-files", file);
      console.log("formdata===>", formdata);
      try {
        const response = await imageAxiosInstance.post("/upload", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("respons=====dede==>", response);
        setImageData(response.data?.data[0]?.mediaLink);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error====>", error);
      }
    }
  };

  const uploadFileLogo = async (e) => {
    let file = e.target.files[0];
    console.log("file====>", file);
    if (file !== undefined) {
      console.log("dbdhcbdcdbhcbdhcbh0", file);
      setTenantLoading(true);
      var formdata = new FormData();
      formdata.append("multi-files", file);
      try {
        const response = await imageAxiosInstance.post("/upload", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setTenantLogo(response.data?.data[0]?.mediaLink);
        setTenantLoading(false);
      } catch (error) {
        setTenantLoading(false);
        console.log("error====>", error);
      }
    }
  };

  const leaseTeemImageFill = async (e) => {
    let file = e.target.files[0];

    if (file !== undefined) {
      var formdata = new FormData();
      formdata.append("multi-files", file);
      try {
        const response = await imageAxiosInstance.post("/upload", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setLaseTeemImage(response.data?.data[0]?.mediaLink);
      } catch (error) {
        console.log("error====>", error);
      }
    }
  };
  const propertyMetaInfoImg = async (e, index) => {
    let file = e.target.files[0];
    // console.log("file====>", file);
    if (file !== undefined) {
      // console.log("dbdhcbdcdbhcbdhcbh0", file);
      // setTenantLoading(true);

      var formdata = new FormData();
      formdata.append("multi-files", file);
      try {
        const response = await imageAxiosInstance.post("/upload", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        let newArr = [...propertyMetaInfoImage];
        newArr[index] = response?.data?.data[0]?.mediaLink;
        setPropertyMetaInfoImage(newArr);
        // setPropertyMetaInfoImage([
        //   ...propertyMetaInfoImage,
        //   response?.data?.data[0]?.mediaLink,
        // ]);
        // setTenantLoading(false);
      } catch (error) {
        // setTenantLoading(false);
        console.log("error====>", error);
      }
    }
  };
  const propertyImgDataDelete = (index) => {
    const newArr = [...propertyMetaInfoImage];
    newArr.splice(index, 1);
    setPropertyMetaInfoImage(newArr);
  };
  const amenitiesImg = async (e, index) => {
    let file = e.target.files[0];
    // console.log("file====>", file);
    if (file !== undefined) {
      // console.log("dbdhcbdcdbhcbdhcbh0", file);
      // setTenantLoading(true);

      var formdata = new FormData();
      formdata.append("multi-files", file);
      try {
        const response = await imageAxiosInstance.post("/upload", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        let newArr = [...amenitiesImage];
        newArr[index] = response?.data?.data[0]?.mediaLink;
        setAmenitiesImage(newArr);
        // setAmenitiesImage([
        //   ...amenitiesImage,
        //   response?.data?.data[0]?.mediaLink,
        // ]);
        // setTenantLoading(false);
      } catch (error) {
        // setTenantLoading(false);
        console.log("error====>", error);
      }
    }
  };
  const amenitiesDataDelete = (index) => {
    const newArr = [...amenitiesImage];
    newArr.splice(index, 1);
    setAmenitiesImage(newArr);
  };
  console.log("delete----->");

  const documents = async (e, index) => {
    let file = e.target.files[0];
    // console.log("file====>", file);
    if (file !== undefined) {
      // console.log("dbdhcbdcdbhcbdhcbh0", file);
      // setTenantLoading(true);

      var formdata = new FormData();
      formdata.append("multi-files", file);
      try {
        const response = await imageAxiosInstance.post("/upload", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        let newArr = [...documentsLink];
        newArr[index] = response?.data?.data[0]?.mediaLink;

        setDocumentsLink(newArr);

        // setTenantLoading(false);
      } catch (error) {
        // setTenantLoading(false);
        console.log("error====>", error);
      }
    }
  };
  const reportDataDelete = (index) => {
    const newArr = [...documentsLink];
    newArr.splice(index, 1);
    setDocumentsLink(newArr);
  };
  const propertyManagerImgFun = async (e) => {
    let file = e.target.files[0];
    // console.log("file====>", file);
    if (file !== undefined) {
      // console.log("dbdhcbdcdbhcbdhcbh0", file);
      // setTenantLoading(true);

      var formdata = new FormData();
      formdata.append("multi-files", file);
      try {
        const response = await imageAxiosInstance.post("/upload", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setPropertyManagerImage(response?.data?.data[0]?.mediaLink);
        // setTenantLoading(false);
      } catch (error) {
        // setTenantLoading(false);
        console.log("error====>", error);
      }
    }
  };

  const floorplanFillTow = async (e, index) => {
    let file = e.target.files[0];
    // console.log("file====>", file);
    // setIsFileChanged(true);
    if (file !== undefined) {
      // console.log("dbdhcbdcdbhcbdhcbh0", file);
      // setTenantLoading(true);
      var formdata = new FormData();
      formdata.append("multi-files", file);
      try {
        const response = await imageAxiosInstance.post("/upload", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        let newArr = [...floorFillTow];
        newArr[index] = response?.data?.data[0]?.mediaLink;
        // setFloorFillTow([...floorFillTow, response?.data?.data[0]?.mediaLink]);
        setFloorFillTow(newArr);
        // setFloorFillTow([...floorFillTow, response?.data?.data[0]?.mediaLink]);
        // setTenantLoading(false);
      } catch (error) {
        // setTenantLoading(false);
        console.log("error====>", error);
      }
    }
  };
  const floorDataDelete = (index) => {
    const newArr = [...floorFillTow];
    newArr.splice(index, 1);
    setFloorFillTow(newArr);
  };
  console.log("floor fill tow", floorFillTow);
  // const floorplanFillOne = async (e) => {
  //   let file = e.target.files[0];
  //   // console.log("file====>", file);
  //   if (file !== undefined) {
  //     console.log("dbdhcbdcdbhcbdhcbh0", file);
  //     // setTenantLoading(true);
  //     var formdata = new FormData();
  //     formdata.append("multi-files", file);
  //     try {
  //       const response = await imageAxiosInstance.post("/upload", formdata, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       });
  //       setFloorFillOne([...floorFillOne, response?.data?.data[0]?.mediaLink]);
  //       // setTenantLoading(false);
  //     } catch (error) {
  //       // setTenantLoading(false);
  //       console.log("error====>", error);
  //     }
  //   }
  // };

  const handleGeo = async () => {
    let arrayOne = [];
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${geoLoc}&key=${API_KEY}`
      );
      console.log("HHHHH", response?.data?.results[0]?.geometry?.location);
      setGeoCordLat(response?.data?.results[0]?.geometry?.location?.lat);
      setGeoCordLng(response?.data?.results[0]?.geometry?.location?.lng);
      arrayOne.push(
        response?.data?.results[0]?.geometry?.location?.lat,
        response?.data?.results[0]?.geometry?.location?.lng
      );
      setGeoArr(arrayOne);
      console.log("GGG", arrayOne);
    } catch (error) {
      console.log("error");
    }
  };

  const getCoordinates = (e) => {
    let arrayOne = [];

    setGeoCordLat(e.latLng.lat());
    setGeoCordLng(e.latLng.lng());
    arrayOne.push(e.latLng.lat(), e.latLng.lng());
    setGeoArr(arrayOne);
  };

  // console.log("======>floorFillone====>", floorFillOne);
  const handleFormSubmit = async (values) => {
    console.log("SDDDDDDDDD", geoArr);
    values.location.coordinates = geoArr;
    values.location.type = "Point";
    values.featureImage = imageData;
    delete values.logo;
    values.tenantLogo = tenantLogo;
    delete values.tenantImage;
    values.leaseTerm = leaseTeemImage;
    delete values.leaseTermImg;
    values.gallaryImages = gallaryImg;
    delete values.gallaryImg;
    values.report = fileArr;
    delete values.reportImg;
    values.amenities?.forEach((element, i) => {
      element.image = amenitiesImage?.[i];
    });
    values.propetyMetaInfo?.forEach((element, i) => {
      element.image = propertyMetaInfoImage?.[i];
    });
    delete values.img;
    values.documents?.forEach((element, i) => {
      element.link = documentsLink?.[i];
    });
    // values.floorPlan?.forEach((element, i) => {
    //   element.floor = floorFillOne?.[i];
    // });
    // delete values.floorImgOne;
    values.floorPlan?.forEach((element, i) => {
      element.img = floorFillTow?.[i];
    });
    delete values.floorImgTow;
    values.propertyManagerImage = propertyManagerImage;
    delete values.propertyManagerImg;
    try {
      const response = await adminAxiosInstance.post("/propertyList/add", {
        ...values,
      });
      console.log("form==data===>", response);
      SweetAlert({
        icon: "success",
        text: "you data has Submit",
        title: "Success",
        timer: 1500,
      });
      navigate("/admin/equity-crowdfunding/real-estate");
    } catch (err) {
      console.log("err ==>", err);

      SweetAlert({
        icon: "error",
        text: "something Went Wrong!",
        title: "Warning!",
      });
    }
    console.log("form-value===>", values); // values.content = content;
  };
  // useEffect(() => {}, [uploadPdf]);

  useEffect(() => {
    if (tenantLogo !== undefined) {
      setTenantUrl(tenantLogo);
      setTenantLoading(false);
    }
  }, [tenantLogo]);

  useEffect(() => {
    if (imageData !== undefined) {
      setUrl(imageData);
      setLoading(false);
    }
  }, [imageData]);
  console.log("image--data--->", imageData);
  console.log("tenantLOgo--->", tenantLogo);
  console.log("gallary_image-->", gallaryImg);
  // const colourOptions = [
  //   { value: "gym", label: "Gym" },
  //   { value: "parking", label: "Parking" },
  //   { value: "water", label: "Water" },
  //   { value: "internet", label: "Internet" },
  //   { value: "gardern", label: "Gardern" },
  //   { value: "balcony", label: "Balcony" },
  //   { value: "outdoor-kitchen", label: "Outdoor Kitchen" },
  //   { value: "24/7help", label: "24/7 Surveillance" },
  // ];

  return (
    <>
      <div>
        <CRow>
          <CCol xl={12}>
            <div className="header-view">
              <CIcon className="logo" icon={cilGroup}></CIcon>
              <h3 className="title">RealEstate Management</h3>
              {/* <div className="sub-title mb-3">Edit Real Estate</div> */}
            </div>
          </CCol>
          <CCol xl={12}>
            <button
              className="btn btn-info text-white mb-2"
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
          </CCol>
        </CRow>
        <CRow className="bg-white border-top border-info border-3">
          <CCol xl={12}>
            <h4 className="py-2">Company Details</h4>
          </CCol>
          <CCol>
            <Formik
              enableReinitialize={true}
              initialValues={{
                logo: "",
                overview: "",
                name: "",
                targetIRR: "",
                dividentYield: "",
                featureImage: "",
                urlName: "",
                tenantImage: "",
                svp: "",
                propertyManagerWebsiteUrl: "",
                propertyManagerAddress: "",
                propertyManagerImage: "",
                grossYield: "",
                location: {
                  type: "Point",
                  coordinates: [],
                },
                locationStr: "",
                commissionAmount: "",
                minimunInvestment: "",
                report: "",
                reportImg: "",
                fiveYearExpectedReturn: "",
                remainingPeriod: "",
                sharePrice: "",
                gallaryImages: "",
                gallaryImg: ["", "", ""],
                tenantLogo: "",
                // increaseRate: "",
                // averageDailyRate: "",
                // occupancy: "",
                // expense: "",
                // expectedCapitalAppreciation: "",
                projectedReturn: "",
                totalAmount: "",
                dividentYield: "",
                suggestedHoldingPeriod: "",
                propertyType: "",
                area: "",
                leaseTermImg: "",

                amenities: [
                  {
                    img: "",
                    image: "",
                    name: "",
                  },
                ],
                propetyMetaInfo: [
                  {
                    img: "",
                    image: "",
                    name: "",
                  },
                ],
                floorPlanDetails: "",
                leaseCommencement: "",
                status: "",
                monthlyRentRupeePerSQFT: "",
                escalation: "",
                securityDeposit: "",
                leaseTerm: "",
                iframeUrl: "",
                videoUpload: "",
                propertyManagerName: "",
                propertyManagerDetails: "",
                propertyManagerImg: "",
                propertyMetaInfoImage: "",
                whyInvest: "",
                developer: "",
                developerDetails: "",
                tenantName: "",
                tenantOverview: "",
                averageRentalYield: "",
                remainingPeriod: "",
                leaseStructureDetails: "",
                leaseStart: "",
                leaseEnd: "",
                leaseLockIn: "",
                documents: [
                  {
                    img: "",
                    link: "",
                    name: "",
                  },
                ],
                floorPlan: [
                  {
                    // floor: "",
                    // floorImgOne: "",
                    description: "",
                    area: "",
                    floorImgTow: "",
                    img: "",
                    room: "",
                    bed: "",
                    bath: "",
                  },
                ],
                metaTitle: "",
                metaDescription: "",
                metaKeywords: "",
                metaRobots: "",
                canonicalTag: "",
                ogTitle: "",
                ogType: "",
                ogImage: "",
                ogUrl: "",
                ogDescription: "",
              }}
              onSubmit={(values) => handleFormSubmit(values)}
              validationSchema={addRealEstateSchema}
            >
              {({
                values,
                errors,
                handleChange,
                isSubmitting,
                handleBlur,
                touched,
                setFieldValue,
              }) => (
                <Form>
                  <div className="form g-3 p-3">
                    <CRow>
                      <CCol xl={6}>
                        <div className="row">
                          <div className="col-12 mb-3">
                            <label
                              htmlFor="Name"
                              className="form-label fw-label"
                            >
                              Property Name
                            </label>
                            <input
                              type="text"
                              id="Name"
                              placeholder="Name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-control${
                                errors.name && touched.name ? " is-invalid" : ""
                              }`}
                            />
                          </div>
                        </div>
                      </CCol>
                      <div className="text-danger">
                        <ErrorMessage name="name" />
                      </div>
                      {/* image for only */}
                      <div className="col-xl-6 mb-3">
                        <label htmlFor="logo" className="form-label fw-label">
                          Featured Image
                        </label>
                        {loading ? (
                          <div>
                            <img
                              className="img-fluid"
                              src={ErrorImg}
                              alt="blog"
                            />
                          </div>
                        ) : (
                          <div className="text-center">
                            <img
                              className="img-fluid max_img_view_admin"
                              src={url}
                              alt="companyLogo"
                            />
                          </div>
                        )}
                        <input
                          type="file"
                          className="form-control"
                          id="logo"
                          accept="image/*"
                          name="logo"
                          // value={values.logo}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            uploadFile(e);
                            setFieldValue("logo", e.target.files[0]);
                            handleChange(e);
                          }}
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="totalAmount"
                          className="form-label fw-label"
                        >
                          Price
                        </label>
                        <input
                          className={`form-control${
                            errors.totalAmount && touched.totalAmount
                              ? " is-invalid"
                              : ""
                          }`}
                          type="number"
                          id="totalAmount"
                          placeholder="Price"
                          name="totalAmount"
                          value={values.totalAmount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="text-danger">
                          <ErrorMessage name="totalAmount" />
                        </div>
                      </div>

                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="minInvest"
                          className="form-label fw-label"
                        >
                          Min Investment
                        </label>
                        <input
                          type="number"
                          id="minInvest"
                          placeholder="Min Investment"
                          name="minimunInvestment"
                          value={values.minimunInvestment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control${
                            errors.minimunInvestment &&
                            touched.minimunInvestment
                              ? " is-invalid"
                              : ""
                          }`}
                        />
                        <div className="text-danger">
                          <ErrorMessage name="minimunInvestment" />
                        </div>
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="avgRentalYield"
                          className="form-label fw-label"
                        >
                          Avg. Rental Yield
                        </label>
                        <input
                          type="number"
                          id="averageRentalYield"
                          placeholder="Average Rental Yield"
                          name="averageRentalYield"
                          value={values.averageRentalYield}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control`}
                          // disabled
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="targetIRR"
                          className="form-label fw-label"
                        >
                          Target IRR
                        </label>
                        <input
                          type="number"
                          id="targetIRR"
                          placeholder="Target IRR"
                          name="targetIRR"
                          value={values.targetIRR}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          // disabled
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="metaTitle"
                          className="form-label fw-label"
                        >
                          Meta Title
                        </label>
                        <textarea
                          rows="4"
                          cols="50"
                          type="text"
                          id="metaTitle"
                          name="metaTitle"
                          placeholder="title"
                          value={values.metaTitle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control `}
                        ></textarea>
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="metaDescription"
                          className="form-label fw-label"
                        >
                          Meta Description
                        </label>
                        <textarea
                          rows="4"
                          cols="50"
                          type="text"
                          id="metaDescription"
                          name="metaDescription"
                          placeholder="Meta Description"
                          value={values.metaDescription}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control `}
                        ></textarea>
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="metaKeywords"
                          className="form-label fw-label"
                        >
                          Meta Keyword
                        </label>
                        <textarea
                          rows="4"
                          cols="50"
                          type="text"
                          id="metaKeywords"
                          name="metaKeywords"
                          placeholder="Meta keyword"
                          value={values.metaKeywords}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control `}
                        ></textarea>
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="metaRobots"
                          className="form-label fw-label"
                        >
                          Meta Robots
                        </label>
                        <textarea
                          rows="4"
                          cols="50"
                          type="text"
                          id="metaRobots"
                          name="metaRobots"
                          placeholder="Meta Robots"
                          value={values.metaRobots}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control `}
                        ></textarea>
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="canonicalTag"
                          className="form-label fw-label"
                        >
                          Canonical Tag
                        </label>
                        <textarea
                          rows="4"
                          cols="50"
                          type="text"
                          id="canonicalTag"
                          name="canonicalTag"
                          placeholder="Canonical Tag"
                          value={values.canonicalTag}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control `}
                        ></textarea>
                      </div>

                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="ogTitle"
                          className="form-label fw-label"
                        >
                          ogTitle
                        </label>
                        <textarea
                          rows="4"
                          cols="50"
                          type="text"
                          id="ogTitle"
                          name="ogTitle"
                          placeholder="ogTitle"
                          value={values.ogTitle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control `}
                        ></textarea>
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label htmlFor="ogType" className="form-label fw-label">
                          ogType
                        </label>
                        <textarea
                          rows="4"
                          cols="50"
                          type="text"
                          id="ogType"
                          name="ogType"
                          placeholder="ogType"
                          value={values.ogType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control `}
                        ></textarea>
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="ogImage"
                          className="form-label fw-label"
                        >
                          ogImage
                        </label>
                        <textarea
                          rows="4"
                          cols="50"
                          type="text"
                          id="ogImage"
                          name="ogImage"
                          placeholder="ogImage"
                          value={values.ogImage}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control `}
                        ></textarea>
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label htmlFor="ogUrl" className="form-label fw-label">
                          ogUrl
                        </label>
                        <textarea
                          rows="4"
                          cols="50"
                          type="text"
                          id="ogUrl"
                          name="ogUrl"
                          placeholder="ogUrl"
                          value={values.ogUrl}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control `}
                        ></textarea>
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="ogDescription"
                          className="form-label fw-label"
                        >
                          ogDescription
                        </label>
                        <textarea
                          rows="4"
                          cols="50"
                          type="text"
                          id="ogDescription"
                          name="ogDescription"
                          placeholder="ogDescription"
                          value={values.ogDescription}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control `}
                        ></textarea>
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="targetIRR"
                          className="form-label fw-label"
                        >
                          svp
                        </label>
                        <input
                          type="text"
                          id="targetIRR"
                          placeholder="svp"
                          name="svp"
                          value={values.svp}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          // disabled
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="dividentYield"
                          className="form-label fw-label"
                        >
                          Dividend Yield
                        </label>
                        <input
                          type="number"
                          id="dividentYield"
                          placeholder="Dividend Yield"
                          name="dividentYield"
                          value={values.dividentYield}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                      </div>
                      {/* ===== */}
                      {/* <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="increaseRate"
                          className="form-label fw-label"
                        >
                          Increase Rate
                        </label>
                        <input
                          type="number"
                          id="increaseRate"
                          placeholder="Increase Rate"
                          name="increaseRate"
                          value={values.increaseRate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                      </div> */}
                      {/* <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="averageDailyRate"
                          className="form-label fw-label"
                        >
                          Average Daily Rate
                        </label>
                        <input
                          type="number"
                          id="averageDailyRate"
                          placeholder="Average Daily Rate"
                          name="averageDailyRate"
                          value={values.averageDailyRate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                      </div> */}
                      {/* <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="occupancy"
                          className="form-label fw-label"
                        >
                          Occupancy
                        </label>
                        <input
                          type="number"
                          id="occupancy"
                          placeholder="Occupancy"
                          name="occupancy"
                          value={values.occupancy}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                      </div> */}
                      {/* <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="expense"
                          className="form-label fw-label"
                        >
                          Expense
                        </label>
                        <input
                          type="number"
                          id="expense"
                          placeholder="Expense"
                          name="expense"
                          value={values.expense}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                      </div> */}
                      {/* <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="expectedCapitalAppreciation"
                          className="form-label fw-label"
                        >
                          Expected Capital Appreciation
                        </label>
                        <input
                          type="number"
                          id="expectedCapitalAppreciation"
                          placeholder="Expected Capital Appreciation"
                          name="expectedCapitalAppreciation"
                          value={values.expectedCapitalAppreciation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                      </div> */}

                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="grossYield"
                          className="form-label fw-label"
                        >
                          Gross Yield
                        </label>
                        <input
                          type="number"
                          id="grossYield"
                          placeholder="Gross Yield"
                          name="grossYield"
                          value={values.grossYield}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          // disabled
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="commissionAmount "
                          className="form-label fw-label"
                        >
                          Commission Amount
                        </label>
                        <input
                          type="number"
                          id="commissionAmount"
                          placeholder="Commission Amount"
                          name="commissionAmount"
                          value={values.commissionAmount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          // disabled
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="sharePrice"
                          className="form-label fw-label"
                        >
                          Share Price
                        </label>
                        <input
                          type="number"
                          id="sharePrice"
                          placeholder="Share Price "
                          name="sharePrice"
                          value={values.sharePrice}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control${
                            errors.sharePrice && touched.sharePrice
                              ? " is-invalid"
                              : ""
                          }`}
                          // disabled
                        />
                        <div className="text-danger">
                          <ErrorMessage name="sharePrice" />
                        </div>
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="suggestedHoldingPeriod"
                          className="form-label fw-label"
                        >
                          Suggested Holding Period
                        </label>
                        <input
                          type="number"
                          id="suggestedHoldingPeriod"
                          placeholder="Suggested Holding Period"
                          name="suggestedHoldingPeriod"
                          value={values.suggestedHoldingPeriod}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="propertyType"
                          className="form-label fw-label"
                        >
                          Property Type
                        </label>
                        <input
                          type="text"
                          id="propertyType"
                          placeholder="Property Type"
                          name="propertyType"
                          value={values.propertyType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control${
                            errors.propertyType && touched.propertyType
                              ? " is-invalid"
                              : ""
                          }`}
                        />
                        <div className="text-danger">
                          <ErrorMessage name="propertyType" />
                        </div>
                      </div>
                      {/* <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="numberOfInvestors"
                          className="form-label fw-label"
                        >
                          Number of Investors
                        </label>
                        <input
                          type="number"
                          id="numberOfInvestors"
                          placeholder="Number of Investors"
                          name="numberOfInvestors"
                          value={values.numberOfInvestors}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          disabled
                        />
                      </div> */}
                      <div className="col-xl-6 mb-3">
                        <label
                          htmlFor="projectedReturn"
                          className="form-label fw-label"
                        >
                          Projected Return
                        </label>
                        <input
                          type="number"
                          id="projectedReturn"
                          placeholder="Projected Returns"
                          name="projectedReturn"
                          value={values.projectedReturn}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          // disabled
                        />
                      </div>
                      <CCol xl={12}>
                        <div className="row">
                          <div className="col-xl-6 mb-3">
                            <label
                              htmlFor="area"
                              className="form-label fw-label"
                            >
                              Area
                            </label>
                            <input
                              id="area"
                              type="number"
                              name="area"
                              placeholder="Area"
                              value={values.area}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                          </div>
                          <div className="col-xl-6 mb-3">
                            <label
                              htmlFor="iframeUrl "
                              className="form-label fw-label"
                            >
                              Iframe Url
                            </label>
                            <input
                              id="iframeUrl"
                              type="text"
                              name="iframeUrl"
                              placeholder="Iframe Url"
                              value={values.iframeUrl}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                          </div>
                          <div className="col-xl-6 mb-3">
                            <label
                              htmlFor="iframeUrl "
                              className="form-label fw-label"
                            >
                              Video Upload
                            </label>
                            <input
                              id="videoUpload"
                              type="text"
                              name="videoUpload"
                              placeholder="video Upload"
                              value={values.videoUpload}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                          </div>
                          <div className="col-xl-6 mb-3">
                            <label
                              htmlFor="fiveYearExpectedReturn"
                              className="form-label fw-label"
                            >
                              5 Year Expected Return
                            </label>
                            <input
                              type="number"
                              id="fiveYearExpectedReturn"
                              name="fiveYearExpectedReturn"
                              placeholder="Year Expected Plan"
                              value={values.fiveYearExpectedReturn}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                          </div>
                          {/* <div className="text-danger">
                            <ErrorMessage name="fiveYearExpectedReturn" />
                          </div> */}
                          {/* <div className="col-xl-6 mb-3">
                            <label
                              htmlFor="funded"
                              className="form-label fw-label"
                            >
                              Funded
                            </label>
                            <input
                              type="number"
                              id="funded"
                              name="funded"
                              placeholder="funded"
                              value={values.funded}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                              disabled
                            />
                          </div> */}
                          <div className="col-xl-6 mb-3">
                            <label
                              htmlFor="remainingPeriod"
                              className="form-label fw-label"
                            >
                              Remaining Period
                            </label>
                            <input
                              type="date"
                              id="remainingPeriod"
                              name="remainingPeriod"
                              placeholder="Remaining Period"
                              value={values.remainingPeriod}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-control${
                                errors.averageRentalYield &&
                                touched.averageRentalYield
                                  ? " is-invalid"
                                  : ""
                              }`}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="remainingPeriod" />
                            </div>
                          </div>
                          {/* <div className="col-xl-6 mb-3">
                            <label
                              htmlFor="numberInvestment"
                              className="form-label fw-label"
                            >
                              Number of Investors
                            </label>
                            <input
                              id="numberInvestment"
                              name="numberInvestment"
                              placeholder="Number of Investors"
                              value={values.numberInvestment}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                          </div> */}
                          {/* <div className="col-xl-6 mb-3">
                            <label
                              htmlFor="totalInvestmentNeeded"
                              className="form-label fw-label"
                            >
                              Total Investment Needed
                            </label>
                            <input
                              type="number"
                              id="totalInvestmentNeeded"
                              name="totalInvestmentNeeded"
                              placeholder="Total Investment Needed"
                              value={values.totalInvestmentNeeded}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                              disabled
                            />
                          </div> */}
                          <CCol xl={4} md={6} sm={6} className="mb-3">
                            <div className="form-group">
                              <label htmlFor="name" className="form-label">
                                Status
                              </label>
                              <select
                                type="text"
                                id="name"
                                className={`form-select${
                                  errors.status && touched.status
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="status"
                                // defaultValue=""
                                value={values.status}
                                onChange={(e) => {
                                  // handleSelect(e);
                                  handleChange(e);
                                }}
                              >
                                <option value="">Choose</option>
                                <option value="Live">Live</option>
                                <option value="Upcoming">Upcoming</option>
                                <option value="Completed">Completed</option>
                              </select>
                              <div className="text-danger">
                                <ErrorMessage name="status" />
                              </div>
                            </div>
                          </CCol>

                          {/* <CCol xl={12}>
                            <div className="row">
                              <CCol xl={6}>
                                <div className="mb-4">
                                  <label
                                    htmlFor="report"
                                    className="fw-bold mb-2"
                                  >
                                    Reports
                                  </label>
                                  <input
                                    type="file"
                                    className="form-control"
                                    id="report"
                                    // accept="application/pdf"
                                    accept="image"
                                    name="reportImg"
                                    multiple
                                    // value={values.reportImg}
                                    onChange={(e) => {
                                      uploadPdf(e);
                                      setFieldValue(
                                        "reportImg",
                                        e.target.files[0]
                                      );
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </CCol>
                              <CCol xl={12}>
                                {fileArr.length !== 0 ? (
                                  <table className="table table-striped table-light table-hover">
                                    <thead>
                                      <tr>
                                        <th scope="col">Sr No</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Link</th>
                                        <th scope="col">Delet</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {fileArr?.map((item, index) => (
                                        <tr key={index}>
                                          <th scope="row">{index + 1}</th>
                                          <td>Fill</td>
                                          <td>
                                            <a href={`${item}`} target="_blank">
                                              {item}
                                            </a>
                                          </td>
                                          <td>
                                            <button
                                              className="btn btn-danger text-white"
                                              onClick={() => deleteFile(index)}
                                            >
                                              <CIcon
                                                icon={cilTrash}
                                                size="sm"
                                                className="text-white"
                                              />
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <></>
                                )}
                              </CCol>
                            </div>
                          </CCol> */}
                        </div>
                      </CCol>

                      <CCol xl={6}>
                        <div className="border-1">
                          <label htmlFor="amenities" className="fw-bold mb-2">
                            Amenities
                          </label>
                          <FieldArray
                            name="amenities"
                            render={(arrayHelpers2) => {
                              bindArrayHelpers2(arrayHelpers2);
                              return (
                                <>
                                  {values.amenities &&
                                    values.amenities?.map((item, index) => (
                                      <div
                                        className={`row ${
                                          index % 2 == 0 && "bg-light"
                                        }`}
                                        key={index}
                                      >
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                          <label
                                            htmlFor={`img-floor${index}`}
                                            className="cursor-pointer"
                                          >
                                            <img
                                              src={
                                                amenitiesImage?.[index]
                                                  ? amenitiesImage?.[index]
                                                  : ErrorImage
                                              }
                                              className="rounded-3 equity-image"
                                              alt=""
                                            />
                                          </label>

                                          <Field
                                            // placeholder="Enter Stock Name"
                                            type="file"
                                            name={`amenities.${index}.img`}
                                            onChange={(e) => {
                                              amenitiesImg(e, index);
                                            }}
                                            className="form-control"
                                          />
                                          <div className="mt-2 mb-3">
                                            <Field
                                              placeholder="Enter Amenities Name"
                                              name={`amenities.${index}.name`}
                                              className="form-control"
                                            />
                                          </div>

                                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3 d-flex justify-content-center align-items-center">
                                            <div
                                              className=" btn btn-danger text-white"
                                              onClick={() => {
                                                arrayHelpers2.remove(index);
                                                amenitiesDataDelete(index);
                                              }}
                                            >
                                              Remove
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </>
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-secondary my-2 mx-2 text-white"
                            onClick={() => {
                              boundArrayHelpers2.push({
                                img: "",
                                image: "",
                                name: "",
                              });
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </CCol>

                      <CCol xl={6}>
                        <div className="border-1">
                          <label
                            htmlFor="propetyMetaInfo"
                            className="fw-bold mb-2"
                          >
                            Property Meta Info
                          </label>
                          <FieldArray
                            name="propetyMetaInfo"
                            render={(arrayHelpers3) => {
                              bindArrayHelpers3(arrayHelpers3);
                              return (
                                <>
                                  {values.propetyMetaInfo &&
                                    values.propetyMetaInfo?.map(
                                      (item, index) => (
                                        <div
                                          className={`row ${
                                            index % 2 == 0 && "bg-light"
                                          }`}
                                          key={index}
                                        >
                                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                            <label
                                              htmlFor={`img-floor${index}`}
                                              className="cursor-pointer"
                                            >
                                              <img
                                                src={
                                                  propertyMetaInfoImage?.[index]
                                                    ? propertyMetaInfoImage?.[
                                                        index
                                                      ]
                                                    : ErrorImage
                                                }
                                                className="rounded-3 equity-image"
                                                alt=""
                                              />
                                            </label>

                                            <Field
                                              // placeholder="Enter Stock Name"
                                              type="file"
                                              name={`propetyMetaInfo.${index}.img`}
                                              onChange={(e) => {
                                                propertyMetaInfoImg(e, index);
                                              }}
                                              className="form-control"
                                            />
                                            <div className="mt-2 mb-3">
                                              <Field
                                                placeholder="Enter Property Meta Info Name"
                                                name={`propetyMetaInfo.${index}.name`}
                                                className="form-control"
                                              />
                                            </div>

                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3 d-flex justify-content-center align-items-center">
                                              <div
                                                className=" btn btn-danger text-white"
                                                onClick={() => {
                                                  arrayHelpers3.remove(index);
                                                  propertyImgDataDelete(index);
                                                }}
                                              >
                                                Remove
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                </>
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-secondary my-2 mx-2 text-white"
                            onClick={() => {
                              boundArrayHelpers3.push({
                                img: "",
                                image: "",
                                name: "",
                              });
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </CCol>
                      <CCol xl={12}>
                        <div>
                          <label
                            htmlFor="propertyManagerName"
                            className="form-label"
                          >
                            Url name
                          </label>
                          <input
                            className={`form-control${
                              errors.urlName && touched.urlName
                                ? " is-invalid"
                                : ""
                            }`}
                            type="text"
                            name="urlName"
                            value={values.urlName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onInput={(e) =>
                              (e.target.value = e.target.value.replace(
                                /[^a-z0-9\s]/gi,
                                ""
                              ))
                            }
                            id="search"
                            placeholder="URL Name.."
                          />
                        </div>
                        <div className="text-danger">
                          <ErrorMessage name="urlName" />
                        </div>
                      </CCol>

                      <CCol xl={12}>
                        <div className="mb-4">
                          <label htmlFor="overview" className="fw-bold mb-2">
                            Overview
                          </label>
                          {/* <CKEditor
                            name="overview"
                            id="overview"
                            editor={ClassicEditor}
                            data={values.overview}
                            onReady={(editor) => {}}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              values.overview = data;
                            }}
                            onBlur={(event, editor) => {}}
                            onFocus={(event, editor) => {}}
                          /> */}
                          <input
                            className="form-control"
                            type="text"
                            name="overview"
                            value={values.overview}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="search"
                            placeholder="Overview"
                          />
                        </div>
                      </CCol>
                      <CCol xl={12}>
                        <div className="mb-4">
                          <label htmlFor="whyInvest" className="fw-bold mb-2">
                            Why Invest
                          </label>
                          <CKEditor
                            name="whyInvest"
                            id="whyInvest"
                            editor={ClassicEditor}
                            data={values.whyInvest}
                            onReady={(editor) => {}}
                            onChange={(event, editor) => {
                              let data2 = editor.getData();
                              values.whyInvest = data2;
                            }}
                            onBlur={(event, editor) => {}}
                            onFocus={(event, editor) => {}}
                          />
                        </div>
                      </CCol>
                      <CCol xl={6} className="mb-3">
                        <label htmlFor="locationStr" className="form-label">
                          Location
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="locationStr"
                          value={values.locationStr}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="locationStr"
                          placeholder="Location"
                        />
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol xl={12} className="mb-3">
                        <CCol xl={6} lg={6} md={6}>
                          <div className="d-flex">
                            <input
                              className="form-control"
                              type="text"
                              id="search"
                              placeholder="search location..."
                              name="serachLocation"
                              value={geoLoc}
                              onChange={(e) => setGeoLoc(e.target.value)}
                            />
                            <button
                              type="button"
                              className="btn btn-info text-white fw-bolder me-3 mx-3"
                              onClick={() => handleGeo()}
                            >
                              Submit
                            </button>
                          </div>
                        </CCol>

                        <div className="d-flex">
                          <input
                            className="form-control my-3 mx-2 form-my"
                            type="text"
                            name="geoCordLat"
                            value={geoCordLat}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled
                          />
                          <input
                            className="form-control my-3 mx-2 form-my"
                            type="text"
                            name="geoCordLng"
                            value={geoCordLng}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled
                          />
                        </div>
                        <GoogleMap
                          lat={geoCordLat}
                          lng={geoCordLng}
                          getCoordinates={getCoordinates}
                        />
                      </CCol>
                    </CRow>
                    <div className="my-2">
                      <h3>Seller & Property Manager</h3>
                    </div>
                    <CRow>
                      <CCol xl={6} className="mb-3">
                        <label
                          htmlFor="propertyManagerName"
                          className="form-label"
                        >
                          Property Manager Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="propertyManagerName"
                          value={values.propertyManagerName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="search"
                          placeholder="Property Manager Name..."
                        />
                      </CCol>
                      <CCol xl={6} className="mb-3">
                        <label
                          htmlFor="propertyManagerDetails"
                          className="form-label"
                        >
                          Property Manager Details
                        </label>
                        <textarea
                          id="prop-detail"
                          className="form-control"
                          name="propertyManagerDetails"
                          value={values.propertyManagerDetails}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Property Manager details"
                          col="3"
                          rows={6}
                        />
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol xl={6} className="mb-3">
                        <label
                          htmlFor="propertyManagerWebsiteUrl"
                          className="form-label"
                        >
                          Property Manager Website Url
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="propertyManagerWebsiteUrl"
                          value={values.propertyManagerWebsiteUrl}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="propertyManagerWebsiteUrl"
                          placeholder="Property Manager Website Url"
                        />
                      </CCol>
                      <CCol xl={6} className="mb-3">
                        <label
                          htmlFor="propertyManagerAddress"
                          className="form-label"
                        >
                          Property Manager Address
                        </label>
                        <textarea
                          id="prop-detail"
                          className="form-control"
                          name="propertyManagerAddress"
                          value={values.propertyManagerAddress}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Property Manager details"
                          col="3"
                          rows={6}
                        />
                      </CCol>
                      <CCol>
                        <label>Property Manager Image</label>
                        <label className="form-label">
                          <img
                            src={
                              propertyManagerImage
                                ? propertyManagerImage
                                : ErrorImage
                            }
                            className="rounded-3 equity-image"
                            alt=""
                          />
                        </label>
                        <input
                          className="form-control "
                          id="tenetName"
                          type="file"
                          accept="image/*"
                          name="propertyManagerImg"
                          // value={values.gallaryImg[0]}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            propertyManagerImgFun(e);
                            setFieldValue(
                              "propertyManagerImg",
                              e.target.files[0]
                            );
                            handleChange(e);
                          }}
                        />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol xl={12} className="mb-3">
                        <label htmlFor="developer" className="mb-2">
                          Developer
                        </label>
                        <input
                          className="form-control"
                          id="developer"
                          name="developer"
                          value={values.developer}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          placeholder="Developer"
                        />
                      </CCol>
                      <CCol xl={6} className="mb-3">
                        <label
                          htmlFor="developerDetails"
                          className="form-label"
                        >
                          Devloper Details
                        </label>
                        <textarea
                          id="dev-detail"
                          className="form-control"
                          name="developerDetails"
                          value={values.developerDetails}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Devloper details"
                          col="3"
                          rows={6}
                        />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <h3>Tenancy</h3>
                      </CCol>
                      <CCol xl={12} className="mb-3">
                        <label htmlFor="tenantName" className="mb-2">
                          Tenant Name
                        </label>
                        <input
                          className="form-control mb-2"
                          id="tenantName"
                          name="tenantName"
                          value={values.tenantName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          placeholder="Tenant Name"
                        />
                      </CCol>
                      <CCol xl={4} className="mb-3">
                        <div className="col-xl-6 mb-3">
                          <label htmlFor="tenetName" className="mb-2">
                            Tenant Logo
                          </label>
                          {tenantLoading ? (
                            <div>
                              <img
                                className="img-fluid"
                                src={ErrorImg}
                                alt="blog"
                              />
                            </div>
                          ) : (
                            <div className="text-center">
                              <img
                                className="img-fluid max_img_view_admin"
                                src={tenantUrl}
                                alt="companyLogo"
                              />
                            </div>
                          )}
                          <input
                            className="form-control "
                            id="tenetName"
                            type="file"
                            accept="image/*"
                            name="tenantImage"
                            // value={values.tenantImage}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              uploadFileLogo(e);
                              setFieldValue("tenantImage", e.target.files[0]);
                              handleChange(e);
                            }}
                            placeholder="Tenant Name"
                          />
                        </div>
                      </CCol>
                      <CCol xl={8} className="mb-3">
                        <label htmlFor="tenantOverview" className="mb-2">
                          Tenant Overview
                        </label>
                        <textarea
                          id="tenantOverview"
                          className="form-control"
                          name="tenantOverview"
                          value={values.tenantOverview}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Tentet Overview"
                          col="3"
                          rows={3}
                        />
                      </CCol>
                      <CCol xl={3}>
                        <label htmlFor="leaseStructureDetails" className="mb-2">
                          Lease Structure Details
                        </label>
                        <textarea
                          id="leaseStructureDetails"
                          className="form-control"
                          name="leaseStructureDetails"
                          value={values.leaseStructureDetails}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Lease Structure Details"
                          col="3"
                          rows={3}
                        />
                      </CCol>
                      <CCol xl={3}>
                        <label htmlFor="leaseStart" className="mb-2">
                          Lease Start
                        </label>
                        <input
                          type="date"
                          placeholder="Lease Start"
                          id="leaseStart"
                          name="leaseStart"
                          value={values.leaseStart}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                      </CCol>
                      <CCol xl={3}>
                        <label htmlFor="leaseEnd" className="mb-2">
                          Lease End
                        </label>
                        <input
                          type="date"
                          placeholder=" Lease End"
                          id="leaseEnd"
                          name="leaseEnd"
                          value={values.leaseEnd}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                      </CCol>
                      <CCol xl={3}>
                        <label htmlFor="leaseLockIn" className="mb-2">
                          Lease Lock-In
                        </label>
                        <input
                          type="date"
                          id="lease-lockin"
                          name="leaseLockIn"
                          value={values.leaseLockIn}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Lease Lock-In"
                          className="form-control"
                        />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol className="my-3 d-flex justify-content-center align-items-center">
                        <table className="table table-bordered table-striped">
                          <tbody>
                            <tr>
                              <th scope="row">Lease Commencement</th>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="leaseCommencement"
                                  value={values.leaseCommencement}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Monthly rent(₹/sqft)</th>

                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="monthlyRentRupeePerSQFT"
                                  value={values.monthlyRentRupeePerSQFT}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Escalation %</th>

                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="escalation"
                                  value={values.escalation}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Security Deposit</th>

                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="securityDeposit"
                                  value={values.securityDeposit}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Lease Term</th>

                              <td>
                                <input
                                  type="file"
                                  className="form-control"
                                  id="leaseTermImg"
                                  accept="image/*"
                                  name="leaseTermImg"
                                  // value={values.leaseTermImg}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    leaseTeemImageFill(e);
                                    setFieldValue(
                                      "leaseTermImg",
                                      e.target.files[0]
                                    );
                                    handleChange(e);
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol xl={12}>
                        <label htmlFor="floor-plan">Floor Plan Details</label>
                        <textarea
                          id="floor-plan"
                          className="form-control"
                          type="fill"
                          name="floorPlanDetails"
                          value={values.floorPlanDetails}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Floor Plan Details"
                          col="3"
                          rows={3}
                        />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol className="my-3" xl={12}>
                        <h3>Gallery Images</h3>
                      </CCol>

                      <CCol
                        xl={12}
                        className="my-3 d-flex justify-content-center align-items-center"
                      >
                        <table className="table table-bordered table-striped">
                          <thead className="p-3">
                            <tr>
                              <th className="py-3">Image preview</th>
                              <th className="py-3">Remove</th>
                            </tr>
                          </thead>
                          <tbody>
                            {addGallary?.map((item, i) => (
                              // <div className="col-12">
                              <tr key={i}>
                                <th scope="row">
                                  <label>
                                    <img
                                      src={
                                        gallaryImg?.[i]
                                          ? gallaryImg?.[i]
                                          : ErrorImage
                                      }
                                      className="rounded-3 equity-image"
                                      alt=""
                                    />
                                  </label>

                                  <input
                                    className="form-control "
                                    id="tenetName"
                                    type="file"
                                    accept="image/*"
                                    name="gallaryImg[0]"
                                    // value={values.gallaryImg[0]}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      gallaryImgFun(e, i);
                                      setFieldValue(
                                        "gallaryImg[0]",
                                        e.target.files[0]
                                      );
                                      handleChange(e);
                                    }}
                                    placeholder="Tenant Name"
                                  />
                                </th>
                                <td className="text-danger">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => deleteGallary(i)}
                                  >
                                    Remove
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-secondary text-white"
                                  onClick={() =>
                                    addGallaryImages(addGallary?.length)
                                  }
                                >
                                  Add Images
                                </button>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </CCol>
                      <CCol xl={12} lg={12}>
                        <div className="border-1">
                          <label htmlFor="documents" className="fw-bold mb-2">
                            Reports
                          </label>
                          <FieldArray
                            name="documents"
                            render={(arrayHelpers4) => {
                              bindArrayHelpers4(arrayHelpers4);
                              return (
                                <>
                                  {values.documents &&
                                    values.documents?.map((item, index) => (
                                      <div
                                        className={`row ${
                                          index % 2 == 0 && "bg-light"
                                        }`}
                                        key={index}
                                      >
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                          {/* <label
                                            htmlFor={`img-floor${index}`}
                                            className="cursor-pointer"
                                          ></label> */}

                                          <Field
                                            // placeholder="Enter Stock Name"
                                            type="file"
                                            name={`documents.${index}.img`}
                                            onChange={(e) => {
                                              documents(e, index);
                                            }}
                                            className="form-control"
                                          />
                                          <div className="mt-2 mb-3">
                                            <label>Link :-</label>
                                            <a
                                              href={`${documentsLink?.[index]}`}
                                              target="_blank"
                                            >
                                              {documentsLink?.[index]}
                                            </a>
                                          </div>
                                          <div className="mt-2 mb-3">
                                            <Field
                                              placeholder="Name"
                                              name={`documents.${index}.name`}
                                              className="form-control"
                                            />
                                          </div>

                                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3 d-flex justify-content-center align-items-center">
                                            <div
                                              className=" btn btn-danger text-white"
                                              onClick={() => {
                                                arrayHelpers4.remove(index);
                                                reportDataDelete(index);
                                              }}
                                            >
                                              Remove
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </>
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-secondary my-2 mx-2 text-white"
                            onClick={() => {
                              boundArrayHelpers4.push({
                                img: "",
                                link: "",
                                name: "",
                              });
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </CCol>
                      <CCol className="my-3" xl={12}>
                        <h3>Floor Plan</h3>
                      </CCol>
                      <CCol className="my-3 ">
                        {/* {addFloorArr.length == 0 ? ( */}
                        <div className="border-1">
                          <FieldArray
                            name="floorPlan"
                            render={(arrayHelpers) => {
                              bindArrayHelpers(arrayHelpers);
                              return (
                                <>
                                  {values.floorPlan &&
                                    values.floorPlan?.map((item, index) => (
                                      <div
                                        className={`row ${
                                          index % 2 == 0 && "bg-light"
                                        }`}
                                        key={index}
                                      >
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                          <div>
                                            <label
                                              htmlFor={`img-floor${index}`}
                                              className="cursor-pointer"
                                            >
                                              <img
                                                src={
                                                  floorFillTow?.[index]
                                                    ? floorFillTow?.[index]
                                                    : ErrorImage
                                                }
                                                className="rounded-3 equity-image"
                                                alt=""
                                              />
                                            </label>
                                            <Field
                                              type="file"
                                              name={`floorPlan.${index}.floorImgTow`}
                                              onChange={(e) => {
                                                floorplanFillTow(e, index);
                                              }}
                                              className="form-control form-img"
                                            />
                                          </div>
                                          <div className="mt-2 mb-3">
                                            <Field
                                              placeholder="Enter Description"
                                              name={`floorPlan.${index}.description`}
                                              className="form-control"
                                            />
                                          </div>
                                          <div className="mt-2 mb-3">
                                            <Field
                                              placeholder="Enter  Area"
                                              name={`floorPlan.${index}.area`}
                                              className="form-control"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                          {/* <label
                                            htmlFor={`img-floor${index}`}
                                            className="cursor-pointer"
                                          >
                                            <img
                                              src={
                                                floorFillOne?.[index]
                                                  ? floorFillOne?.[index]
                                                  : ErrorImage
                                              }
                                              className="rounded-3 equity-image"
                                              alt=""
                                            />
                                          </label> */}

                                          {/* <Field
                                            // placeholder="Enter Stock Name"
                                            type="file"
                                            name={`floorPlan.${index}.floorImgOne`}
                                            onChange={(e) => {
                                              floorplanFillOne(e);
                                            }}
                                            className="form-control"
                                          /> */}
                                          <div className=" mt-2 mb-3">
                                            <Field
                                              type="number"
                                              placeholder="Enter  Room"
                                              name={`floorPlan.${index}.room`}
                                              className="form-control"
                                            />
                                          </div>
                                          <div className=" mt-2 mb-3">
                                            <Field
                                              type="number"
                                              placeholder=" Enter Bed"
                                              name={`floorPlan.${index}.bed`}
                                              className="form-control"
                                            />
                                          </div>
                                          <div className=" mt-2 mb-3">
                                            <Field
                                              type="number"
                                              placeholder="Enter Bath"
                                              name={`floorPlan.${index}.bath`}
                                              className="form-control"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3 d-flex justify-content-center align-items-center">
                                          <div
                                            className=" btn btn-danger text-white"
                                            onClick={() => {
                                              arrayHelpers.remove(index);
                                              floorDataDelete(index);
                                            }}
                                          >
                                            Remove
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </>
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-secondary my-2 mx-2 text-white"
                            onClick={() => {
                              boundArrayHelpers.push({
                                // floor: "",
                                // floorImgOne: "",
                                description: "",
                                area: "",
                                floorImgTow: "",
                                img: "",
                                room: "",
                                bed: "",
                                bath: "",
                              });
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </CCol>
                    </CRow>

                    <div className="col-12 form-submit mt-3">
                      <button
                        type="submit"
                        className="btn btn-info text-white fw-bolder me-3"
                        disabled={isSubmitting}
                        // onClick={handleFormSubmit}
                      >
                        {isSubmitting ? "Please wait..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </CCol>
        </CRow>
      </div>
    </>
  );
};

export default RealEstateManagment;
