import React, { useEffect, useState } from "react";
import "./Roles.scss";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import backarrow from "../../assets/svg/back.svg";
import edit from "../../assets/svg/edit.svg";
import remove from "../../assets/svg/delete.svg";
import { useNavigate } from "react-router-dom";
import { cibAddthis } from "@coreui/icons";
import adminAxiosInstance from "../../../config";
import SweetAlert from "../../../helper/SweetAlert";

const Roles = () => {
  const adminToken = localStorage.getItem("token");

  const [roledata, setRoleData] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [id, setId] = useState("");

  const handleDelete = async (id) => {
    try {
      const response = await adminAxiosInstance.delete(
        `/adminRole/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${adminToken}` },
        }
      );
      if (response.status === 200) {
        SweetAlert({
          icon: "success",
          text: "Remove Successfully!!",
          title: "Success",
          timer: 1500,
        });
        setVisible(false);
        fetchData(1, "");
      }
    } catch (error) {
      // console.log("error=====>", error);
    }
  };

  const [editcheckPermissions, setEditCheckPermissions] = useState("");
  const [deletecheckPermissions, setDeleteCheckPermissions] = useState("");
  // const [roledata, setRoleData] = useState([]);
  // const adminToken = localStorage.getItem("token");

  const checkPermissions = async () => {
    try {
      const response = await adminAxiosInstance.get(`/auth/checkPermissions`, {
        headers: { Authorization: `Bearer ${adminToken}` },
      });
      const accessData = response.data.data?.adminRoles?.Access || [];
      // setRoleData(accessData);
      setEditCheckPermissions(response.data.data?.adminRoles?.Access[30]?.Edit);
      setDeleteCheckPermissions(
        response.data.data?.adminRoles?.Access[30]?.Delete
      );
    } catch (error) {
      // Handle error
    }
  };

  // console.log(roledata[30], editcheckPermissions, "5565checkPermissions");
  useEffect(() => {
    checkPermissions();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await adminAxiosInstance.get(
        `/adminRole/getAll?page=1&limit=50`,
        {
          headers: { Authorization: `Bearer ${adminToken}` },
        }
      );
      // console.log("hhhhhhresponse===>", response?.data?.data?.result);
      setRoleData(response.data.data);
      setLoading(false);
    } catch (error) {
      // console.log(error.response.data.message);
      setLoading(false);
    }
  };

  const roleedit = (item, index) => {
    // console.log("741074107410",item?._id,index)
    navigate(`/admin/roles/editroles/${item?._id}`);
  };

  const navigate = useNavigate();
  const Navigate = () => {
    navigate("/admin/roles/addroles");
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="rolepagetitle">
        <h3 className="d-flex align-items-center gap-2">
          <CIcon icon={cibAddthis} customClassName="nav-icon" />
          Role Title
        </h3>
        <div className="d-flex justify-content-end px-2">
          <button
            type="button"
            className="d-flex gap-2 btn btn-info text-white mt-2 px-4 mb-2"
            onClick={() => Navigate()}
            // onClick={navigate("/admin/roles/addroles")}
          >
            <span>+</span>Add
          </button>
        </div>
        <div className="bg-white mt-3 ps-3 pe-3 pb-1 mb-3">
          <table className="table caption-top">
            <caption className="fs-3 text-dark">Role Title</caption>
         
            {loading ? (
              <div className=" loader-content">
                <div className="spinner-border text-secondary"></div>
              </div>
            ) : (
              <>
                 <thead>
              <tr>
                <th scope="col">Sr.No.</th>
                <th scope="col">Name</th>

                {editcheckPermissions === true ? <th scope="col">Edit</th> : ""}
                {deletecheckPermissions === true ? (
                  <th scope="col">Delete</th>
                ) : (
                  ""
                )}
                {/* <th scope="col">Edit</th>
                <th scope="col">Delete</th> */}
              </tr>
            </thead>
              <tbody>
                {roledata?.result?.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td scope="">{item?.RoleTitle}</td>

                    {editcheckPermissions === true ? (
                      <td>
                        <button
                          className="btn btn-info text-white fw-bold"
                          type="button"
                          onClick={() => roleedit(item, index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="icon icon-sm"
                            role="img"
                            aria-hidden="true"
                          >
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M29.663,482.25l.087.087a24.847,24.847,0,0,0,17.612,7.342,25.178,25.178,0,0,0,8.1-1.345l142.006-48.172,272.5-272.5A88.832,88.832,0,0,0,344.334,42.039l-272.5,272.5L23.666,456.541A24.844,24.844,0,0,0,29.663,482.25Zm337.3-417.584a56.832,56.832,0,0,1,80.371,80.373L411.5,180.873,331.127,100.5ZM99.744,331.884,308.5,123.127,388.873,203.5,180.116,412.256,58.482,453.518Z"
                              className="ci-primary"
                            ></path>
                          </svg>
                        </button>
                      </td>
                    ) : (
                      ""
                    )}

                    {deletecheckPermissions === true ? (
                      <td>
                        <CButton
                          onClick={() => {
                            setVisible(!visible);
                            setId(item?._id);
                          }}
                          className="btn btn-danger text-white"
                          type="button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="icon icon-sm"
                            role="img"
                            aria-hidden="true"
                          >
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M96,472a23.82,23.82,0,0,0,23.579,24H392.421A23.82,23.82,0,0,0,416,472V152H96Zm32-288H384V464H128Z"
                              className="ci-primary"
                            ></path>
                            <rect
                              width="32"
                              height="200"
                              x="168"
                              y="216"
                              fill="var(--ci-primary-color, currentColor)"
                              className="ci-primary"
                            ></rect>
                            <rect
                              width="32"
                              height="200"
                              x="240"
                              y="216"
                              fill="var(--ci-primary-color, currentColor)"
                              className="ci-primary"
                            ></rect>
                            <rect
                              width="32"
                              height="200"
                              x="312"
                              y="216"
                              fill="var(--ci-primary-color, currentColor)"
                              className="ci-primary"
                            ></rect>
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M328,88V40c0-13.458-9.488-24-21.6-24H205.6C193.488,16,184,26.542,184,40V88H64v32H448V88ZM216,48h80V88H216Z"
                              className="ci-primary"
                            ></path>
                          </svg>
                        </CButton>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))}

                <CModal
                  alignment="center"
                  visible={visible}
                  onClose={() => setVisible(false)}
                >
                  <CModalHeader>
                    <CModalTitle>Confirm Deletion</CModalTitle>
                  </CModalHeader>
                  <CModalBody>
                    Are you sure you want to delete this item?
                  </CModalBody>
                  <CModalFooter>
                    <CButton
                      color="secondary"
                      className="text-white"
                      onClick={() => setVisible(false)}
                    >
                      Close
                    </CButton>
                    <CButton
                      color="danger"
                      className="text-white"
                      onClick={() => handleDelete(id)}
                    >
                      Delete
                    </CButton>
                  </CModalFooter>
                </CModal>
              </tbody>
              </>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default Roles;
