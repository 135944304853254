import React, { useEffect, useState } from "react";
import "./Employe.scss";
import { Field, Form, Formik } from "formik";
import { CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from "@coreui/react";
import edit from "../../assets/svg/edit.svg";
import remove from "../../assets/svg/delete.svg";
import { useNavigate } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { cilGroup } from "@coreui/icons";
import adminAxiosInstance from "../../../config";
import SweetAlert from "../../../helper/SweetAlert";


const Employeemanage = () => {
const [authbyid, setAuthbyid] = useState("");
const [visible, setVisible] = useState(false);
const [id, setId] = useState("");
const [loading, setLoading] = useState(false);

// console.log(id,"idididid")


const [editcheckPermissions, setEditCheckPermissions] = useState("");
const [deletecheckPermissions, setDeleteCheckPermissions] = useState("");
// const [roledata, setRoleData] = useState([]);
// const adminToken = localStorage.getItem("token");

const checkPermissions = async () => {
  try {
    const response = await adminAxiosInstance.get(`/auth/checkPermissions`, {
      headers: { Authorization: `Bearer ${adminToken}` },
    });
    // const accessData = response.data.data?.adminRoles?.Access || [];
    // setRoleData(accessData);
    setEditCheckPermissions(response.data.data?.adminRoles?.Access[31]?.Edit);
    setDeleteCheckPermissions(
      response.data.data?.adminRoles?.Access[31]?.Delete
    );
  } catch (error) {
    // Handle error
  }
};
// console.log("deletecheckPermissionsdeletecheckPermissions",deletecheckPermissions)
useEffect(() => {
  checkPermissions();
}, []);


const handleDelete = async (id) => {
  try {
    const response = await adminAxiosInstance.delete(`/auth/delete/${id}`,
    {
      headers: { Authorization: `Bearer ${adminToken}` },
    });
    if (response.status === 200) {
      SweetAlert({
        icon: "success",
        text: "Remove Successfully!!",
        title: "Success",
        timer: 1500,
      });
      setVisible(false);
      fetchData(1, "");
    }
  } catch (error) {
    // console.log("error=====>", error);
  }
};

const employeeedit = (item,index) =>{
  // console.log("qqqqqqq",item?._id,index)
  navigate(`/admin/employeemanagement/editemployee/${item?._id}`);
}



const adminToken = localStorage.getItem("token");

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const navigate = useNavigate();
  const Navigate = () => {
    navigate("/admin/employeemanagement/addemployee");
  };


  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await adminAxiosInstance.get(
        `/auth/getAll?page=1&limit=50`,
        {
          headers: { Authorization: `Bearer ${adminToken}` },
        }
      );
      // console.log("setAuthbyid===>", response?.data?.data?.result);
      setAuthbyid(response.data.data);
      setLoading(false);

    } catch (error) {
      setLoading(false);

      // console.log(error.response.data.message);
    }
  };
useEffect(() => {
  fetchData();
}, [])


  return (
    <>
      <div className="employeemanage">
        <h3 className="">
          <CIcon icon={cilGroup} customClassName="nav-icon" />
          Employee Management
        </h3>
        <Formik
          initialValues={{
            toggle: false,
            checked: [],
          }}
          onSubmit={async (values) => {
            await sleep(500);
            alert(JSON.stringify(values, null, 2));
          }}
        >
          {({ values }) => (
            <Form>
              {/* <label>
                  <Field type="checkbox" name="toggle" />
                  {`${values.toggle}`}
                </label>
                <label id="checkbox-group">Checked</label> */}
              <div className="d-flex mt-4 align-items-center gap-3">
                {/* <input className="py-2 px-3" type="text" /> */}
                <div className="d-flex">
                  <button
                    type="submit"
                    className="d-flex gap-2 btn btn-info text-white px-4"
                    onClick={() => Navigate()}
                  >
                    <span>+</span> Add
                  </button>
                </div>
              </div>

              <div className="bg-white mt-3 ps-3 pe-3 pb-1 mb-3">
                <table className="table caption-top mb-0">
                  <caption class="fs-3 text-dark"> Employee Management</caption>
                 
                 {loading ? (<div className="loader-content">
                <div className="spinner-border text-secondary"></div>
              </div>) : 
              <>
               <thead className="border-bottom">
                    <th className="ps-3">Email</th>
                    <th>Name</th>
                    <th>Role type</th>

                    {editcheckPermissions === true ? (
                        <th>Edit</th>
                    ) : (
                      ""
                    )}
                    {deletecheckPermissions === true ? (
                     <th>Delete</th>
                    ) : (
                      ""
                    )}


                
                  </thead>
                  <tbody>

                    {authbyid?.result?.map((item,index) => (


                    <tr className="border-bottom">
                      <td className="ps-3">{item?.email}</td>
                      <td>{item?.fullName}</td>
                      <td>{item?.roleByAdmin?.RoleTitle} </td>


                      {editcheckPermissions === true ? (
                           <td>
                       
                           <button
                             onClick={()=>employeeedit(item,index)}
                             class="btn btn-info text-white fw-bold" 
                             type="button"
                           >
                             <svg
                               xmlns="http://www.w3.org/2000/svg"
                               viewBox="0 0 512 512"
                               class="icon icon-sm"
                               role="img"
                               aria-hidden="true"
                             >
                               <path
                                 fill="var(--ci-primary-color, currentColor)"
                                 d="M29.663,482.25l.087.087a24.847,24.847,0,0,0,17.612,7.342,25.178,25.178,0,0,0,8.1-1.345l142.006-48.172,272.5-272.5A88.832,88.832,0,0,0,344.334,42.039l-272.5,272.5L23.666,456.541A24.844,24.844,0,0,0,29.663,482.25Zm337.3-417.584a56.832,56.832,0,0,1,80.371,80.373L411.5,180.873,331.127,100.5ZM99.744,331.884,308.5,123.127,388.873,203.5,180.116,412.256,58.482,453.518Z"
                                 class="ci-primary"
                               ></path>
                             </svg>
                           </button>
                        
                       </td>

                      ) : (
                        ""
                      )}
                    

                    {deletecheckPermissions === true ? (
                             <td>
                             <CButton  
                              onClick={() => {
                               
                               setVisible(!visible);
                               setId(item?._id);
                             }}
                             class="btn btn-danger text-white" type="button">
                               <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 512 512"
                                 class="icon icon-sm"
                                 role="img"
                                 aria-hidden="true"
                               >
                                 <path
                                   fill="var(--ci-primary-color, currentColor)"
                                   d="M96,472a23.82,23.82,0,0,0,23.579,24H392.421A23.82,23.82,0,0,0,416,472V152H96Zm32-288H384V464H128Z"
                                   class="ci-primary"
                                 ></path>
                                 <rect
                                   width="32"
                                   height="200"
                                   x="168"
                                   y="216"
                                   fill="var(--ci-primary-color, currentColor)"
                                   class="ci-primary"
                                 ></rect>
                                 <rect
                                   width="32"
                                   height="200"
                                   x="240"
                                   y="216"
                                   fill="var(--ci-primary-color, currentColor)"
                                   class="ci-primary"
                                 ></rect>
                                 <rect
                                   width="32"
                                   height="200"
                                   x="312"
                                   y="216"
                                   fill="var(--ci-primary-color, currentColor)"
                                   class="ci-primary"
                                 ></rect>
                                 <path
                                   fill="var(--ci-primary-color, currentColor)"
                                   d="M328,88V40c0-13.458-9.488-24-21.6-24H205.6C193.488,16,184,26.542,184,40V88H64v32H448V88ZM216,48h80V88H216Z"
                                   class="ci-primary"
                                 ></path>
                               </svg>
                             </CButton >
                           
                           </td>
    
                      ) : (
                        ""
                      )}
                   
                    </tr>
                    ))}

                  <CModal
                    alignment="center"
                    visible={visible}
                    onClose={() => setVisible(false)}
                  >
                    <CModalHeader>
                      <CModalTitle>Confirm Deletion</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                      Are you sure you want to delete this item?
                    </CModalBody>
                    <CModalFooter>
                      <CButton
                        color="secondary"
                        className="text-white"
                        onClick={() => setVisible(false)}
                      >
                        Close
                      </CButton>
                      <CButton
                        color="danger"
                        className="text-white"
                        onClick={() => handleDelete(id)}
                      >
                        Delete
                      </CButton>
                    </CModalFooter>
                  </CModal>
                    
                  </tbody>
              </>
              }

                </table>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Employeemanage;
