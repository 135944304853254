// import React from "react";
// import { NavLink, useLocation } from "react-router-dom";
// import PropTypes from "prop-types";

// import { CBadge } from "@coreui/react";

// export const AppSidebarNav = ({ items }) => {
//   const location = useLocation();
//   const navLink = (name, icon, badge) => {
//     return (
//       <>
//         {icon && icon}
//         {name && name}
//         {badge && (
//           <CBadge color={badge.color} className="ms-auto">
//             {badge.text}
//           </CBadge>
//         )}
//       </>
//     );
//   };

//   const navItem = (item, index) => {
//     const { component, name, badge, icon, ...rest } = item;
//     const Component = component;
//     return (
//       <Component
//         {...(rest.to &&
//           !rest.items && {
//             component: NavLink,
//             // activeClassName: 'active',
//           })}
//         key={index}
//         {...rest}
//       >
//         {navLink(name, icon, badge)}
//       </Component>
//     );
//   };
//   const navGroup = (item, index) => {
//     const { component, name, icon, to, ...rest } = item;
//     const Component = component;
//     return (
//       <Component
//         idx={String(index)}
//         key={index}
//         toggler={navLink(name, icon)}
//         visible={location.pathname.startsWith(to)}
//         {...rest}
//       >
//         {item.items?.map((item, index) =>
//           item.items ? navGroup(item, index) : navItem(item, index)
//         )}
//       </Component>
//     );
//   };

//   return (
//     <React.Fragment>
//       {items &&
//         items.map((item, index) =>
//           item.items ? navGroup(item, index) : navItem(item, index)
//         )}
//     </React.Fragment>
//   );
// };

// AppSidebarNav.propTypes = {
//   items: PropTypes.arrayOf(PropTypes.any).isRequired,
// };


import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { CBadge } from "@coreui/react";
import adminAxiosInstance from "../../../config";

export const AppSidebarNav = ({ items }) => {
  const [roledata, setRoleData] = useState([]);
  const adminToken = localStorage.getItem("token");
  const location = useLocation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await adminAxiosInstance.get(`/auth/checkPermissions`, {
        headers: { Authorization: `Bearer ${adminToken}` },
      });
      const accessData = response.data.data?.adminRoles?.Access || [];
      setRoleData(accessData);
    } catch (error) {
      // Handle error
    }
  };

  const isItemAccessible = (item) => {
    return roledata.some(
      (menuTitle) =>
        menuTitle?.Menu?.MenuTitle === item?.name ||
        (item?.items && menuTitle?.Menu?.MenuTitle === item?.items?.name)
    );
  };

  const navLink = (name, icon, badge) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    );
  };
  const navItem = (item, index) => {
    const { component, name, badge, icon, ...rest } = item;
    const Component = component;

    if (isItemAccessible(item)) {
      return (
        <Component
          {...(rest.to &&
            !rest?.items && {
              component: NavLink,
            })}
          key={index}
          {...rest}
        >
          {navLink(name, icon, badge)}
        </Component>
      );
    } else {
      return null;
    }
  };



    
  

  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item;
    const Component = component;
    
   
    if (isItemAccessible(item)) {
     
      const renderedItems = item?.items?.map((item, index) =>
      item?.items ? navGroup(item, index) : navItem(item, index)
      );
      
      // Check if renderedItems contains non-null values
      const hasNonNullItems = renderedItems.some((item) => item !== null);
      // console.log(renderedItems,hasNonNullItems,"fddsfsd")
      
      if (hasNonNullItems) 
      {
        return (
          <Component
          idx={String(index)}
          key={index}
          toggler={navLink(name, icon)}
          visible={location.pathname.startsWith(to)}
          {...rest}
        >
          {item.items?.map((item, index) =>
            item.items ? navGroup(item, index) : navItem(item, index)
          )}
        </Component>
        );
       
      }
    
    } else {
      return (
        <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index)
        )}
      </Component>
      );
    }
  
    return null; // Return null when there are no non-null renderedItems
  };
  

  // console.log('itemsssss', items)
  return (
    <React.Fragment>
      {items &&
        items.map((item, index) =>
          item?.items ? navGroup(item, index) : navItem(item, index)
        )}
    </React.Fragment>
  );
};

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

