import {
  CButton,
  CCol,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ViewSell = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  const fetchData = async () => {};
  return (
    <>
      <CCol>
        <CCol>
          <CButton
            className="text-white"
            color="info"
            onClick={() => navigate(-1)}
          >
            Go Back
          </CButton>
        </CCol>
      </CCol>
      <div className="bg-white mt-3 ps-3 pe-3 pb-1">
        <CTable hover responsive>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">Sr No.</CTableHeaderCell>
              <CTableHeaderCell scope="col">Company Name</CTableHeaderCell>
              <CTableHeaderCell scope="col">Quantity</CTableHeaderCell>
              <CTableHeaderCell scope="col">Selling Price</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {[1, 2, 3, 4].map((item, i) => (
              <CTableRow key={i}>
                <CTableHeaderCell>{i + 1}</CTableHeaderCell>
                <CTableDataCell>john smith</CTableDataCell>
                <CTableDataCell>12</CTableDataCell>
                <CTableDataCell>5000</CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
      </div>
    </>
  );
};

export default ViewSell;
