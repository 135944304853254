// const liveLink = "https://tradeunlisting.herokuapp.com";
const liveLink = "https://api.fracassets.com";
// const liveLink = "https://fraca-dev-backend.vercel.app";
// const liveLink = "http://5421-123-201-1-12.ngrok.io";

export const adminBaseUrl = `${liveLink}/v1/admin`;
export const imageUrl = `${liveLink}/v1/image`;
export const userBaseUrl = `${liveLink}/v1/user`;
export const otpUrl = `${liveLink}/v1/otp`;
export const commonBaseUrl = `${liveLink}/v1/common`;
export const cpBaseUrl = `${liveLink}/v1/cp`;
