import React, { useEffect, useState } from "react";
import backarrow from "../../assets/svg/darkback.svg";
import "./Roles.scss";
import { CCol, CRow } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import CIcon from "@coreui/icons-react";
import { cibAddthis } from "@coreui/icons";
import adminAxiosInstance from "../../../config";
import SweetAlert from "../../../helper/SweetAlert";

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
const adminToken = localStorage.getItem("token");

const RolesAdd = () => {
  const navigate = useNavigate();
  const [menudata, setMenudata] = useState("");
  const [loading, setLoading] = useState(false);

  const Navigate = () => {
    navigate("/admin/roles");
  };

  const handlesumbit = async (values) => {
    // console.log(values, "777777777777");
    try {
      const response = await adminAxiosInstance.post("/adminRole/add", values, {
        headers: { Authorization: `Bearer ${adminToken}` },
      });
      SweetAlert({
        icon: "success",
        text: "Added Successfully!!",
        title: "Success",
        timer: 1500,
      });
      navigate("/admin/roles");
    } catch (error) {
      // console.log(error?.response?.data?.message, "dhruv");
      SweetAlert({
        icon: "error",
        text: error?.response?.data?.message,
        title: "Warning!",
      });
    }
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await adminAxiosInstance.get(
        `/menuAccess/getAll?page=1&limit=50`,
        {
          headers: { Authorization: `Bearer ${adminToken}` },
        }
      );
      // console.log("ssssresponse===>", response?.data?.data?.result);
      setMenudata(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      // console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  // console.log(menudata, "menudatamenudata");

  return (
    <>
      <div className="userroleadd rolepagetitle">
        <h3 className="d-flex align-items-center gap-2">
          <CIcon icon={cibAddthis} customClassName="nav-icon" />
          Add Admin Role
        </h3>
        <div className="bg-white mt-4 p-4 rounded">
          <div className="d-flex  justify-content-between align-items-center">
            <h3 className="rounded mb-0">Add Admin Role</h3>
            <button
              type="button"
              className="btn border-info d-flex align-items-center gap-2 px-4"
              onClick={() => Navigate()}
            >
              <img src={backarrow} alt="" /> Back
            </button>
          </div>
          <div className="selectrole">
            <Formik
              initialValues={{
                RoleTitle: "",
                // Access: menudata?.result?.map((item) => item.MenuTitle) || "",
                Access:
                  menudata?.result?.map((item) => ({
                    Menuid: item.Menuid,
                    MenuTitle: item.MenuTitle,
                    To: item.To,
                    Read: false,
                    Edit: false,
                    Delete: false,
                  })) || [],

                // checked: menudata?.result?.map(() => ({
                //   MenuTitle: '',
                //    Read: false,
                //   Edit: false,
                //   Delete: false,
                // })),
              }}
              onSubmit={handlesumbit}
            >
              {({
                values,
                errors,
                handleChange,
                isSubmitting,
                handleBlur,
                touched,
                setFieldValue,
              }) => (
                <Form>
                  {/* <label>
                  <Field type="checkbox" name="toggle" />
                  {`${values.toggle}`}
                </label>
                <label id="checkbox-group">Checked</label> */}
                  <div className="d-flex flex-column roletitleback my-4">
                    {/* <h5 className="mb-2">Role Title*</h5>
                    <input className="my-custom-input" type="text" /> */}
                    <div className="col-12 mb-3">
                      <label
                        htmlFor="RoleTitle"
                        className="form-label fw-label"
                      >
                        Role Title
                      </label>
                      <input
                        type="text"
                        id="RoleTitle"
                        placeholder="Role Title"
                        name="RoleTitle"
                        value={values.RoleTitle}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                      />
                    </div>
                  </div>
                  {loading ? (
                    <div className="loader-content">
                      <div className="spinner-border text-secondary"></div>
                    </div>
                  ) : (
                    <>
                      {menudata?.result?.map((item, index) => (
                        <CRow className="mb-3">
                          <CCol xs lg={5}>
                            <input
                              type="checkbox"
                              name={`Access[${index}].Menu`}
                              className="me-2"
                              value={item?.Menu}
                              checked={values.Access[index]?.Menu || false}
                              // onChange={(e) =>
                              //   setFieldValue(
                              //     `Access[${index}].Menuid`,
                              //     e.target.checked ? item?.MenuTitle : ""
                              //   )
                              // }
                              onChange={(e) =>
                                setFieldValue(
                                  `Access[${index}].Menu`,
                                  e.target.checked
                                    ? {
                                        MenuId: item?._id,
                                        MenuTitle: item?.MenuTitle,
                                        To: item?.To,
                                      }
                                    : ""
                                )
                              }
                            />
                            <label>{item?.MenuTitle}</label>
                            {/* <label className="userdetail">
        <Field
          type="checkbox"muje
          //  name="checked"
          name={`Access[${index}].MenuTitle`}
          className="me-2"
          //  value="MenuTitle"
          value={item?.MenuTitle}
          // value={values?.checked[index]?.MenuTitle || 'a'}
          // onChange={(e) => setFieldValue(`checked[${index}].MenuTitle`, e?.target?.checked ? item?.MenuTitle : '')}
        />
        {item?.MenuTitle}
      </label> */}
                          </CCol>
                          <CCol xs lg={7}>
                            <div
                              role="group"
                              aria-labelledby="checkbox-group"
                              className="d-flex gap-5"
                            >
                              {/* <input
          type="checkbox"
          name={`Access[${index}].Read`}
          className="me-2"
          checked={values.Access[index]?.Read || false}
          onChange={(e) =>
            setFieldValue(
              `checked[${index}].Read`,
              e.target.checked
            )
          }
        />
        <label>Read</label> */}
                              <label>
                                <Field
                                  type="checkbox"
                                  name={`Access[${index}].Read`}
                                  className="me-2"
                                  // value="Edit"
                                />
                                Read
                              </label>
                              <label>
                                <Field
                                  type="checkbox"
                                  name={`Access[${index}].Edit`}
                                  className="me-2"
                                  // value="Edit"
                                />
                                Edit
                              </label>
                              <label>
                                <Field
                                  type="checkbox"
                                  name={`Access[${index}].Delete`}
                                  className="me-2"
                                  // value="Delete"
                                />
                                Delete
                              </label>
                            </div>
                          </CCol>
                        </CRow>
                      ))}
                    </>
                  )}

                  <button
                    type="submit"
                    className="btn btn-info text-white mt-3"
                  >
                    Edit permissions
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default RolesAdd;
