import React from 'react'
import ProtectedRoutes from './ProtectedRoute'

const Main = ({moduleType}) => {
  return (
    <div>
        <ProtectedRoutes moduleType={moduleType}/>
    </div>
  )
}

export default Main