import { CContainer, CSpinner } from "@coreui/react";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoutes from "../../auth/ProtectedRoute";
import AdminEdit from "../AdminEdit/AdminEdit";
import RealEstateManagment from "../Company/RealEstateManagment";

import AssetsApprove from "../FormData/AssetsApprove";
import BidNow from "../FormData/BidNow";
import TrendingStocks from "../FormData/TrendingStocks";
import UserInquiries from "../FormData/UserInquiries";
import ViewSell from "../FormData/ViewSell";
// import ByWalletManagement from "../transactions/ByWalletManagement";
// import ByProductManagement from "../transactions/ByProductManagement";

import UserManagement from "../UserManagement/UserManagement";
import "./scss/appContent.scss";
import Roles from "../Roles/Roles";
import RolesAdd from "../Roles/RolesAdd";
import Employeemanage from "../Employee/Employeemanage";
import Employeeadd from "../Employee/Employeeadd";
import EditEmployeemanage from "../Employee/EditEmployeemanage";
import EditRoles from "../Roles/EditRoles";
import { useState } from "react";
import { useEffect } from "react";
import adminAxiosInstance from "../../../config";
import Main from "../../auth/Main";

const EditByProduct = React.lazy(() => import("../transactions/EditByProduct"));
const EditByWallet = React.lazy(() => import("../transactions/EditByWallet"));

const EquityEdit = React.lazy(() => import("../Company/EquityEdit"));
const Faq = React.lazy(() => import("../FAQ/Faq"));
const Glossary = React.lazy(() => import("../Glossary/Glossary"));
const StaffManagement = React.lazy(() => import("../Staff/StaffManagement"));
const UserList = React.lazy(() => import("../UserManagement/UserList"));
const OtpList = React.lazy(() => import("../UserManagement/otp"));
const CpList = React.lazy(() => import("../CpManagement/CpList"));
const CpManagement = React.lazy(() => import("../CpManagement/CpManagement"));
const EditCpManagement = React.lazy(() =>
  import("../CpManagement/EditCpManagement")
);
const CpSupport = React.lazy(() => import("../CpManagement/CpSupport"));
const CpKyc = React.lazy(() => import("../CpManagement/CpKyc"));
const CpKycDetails = React.lazy(() => import("../CpManagement/CpKycDetails"));
const EditCpKyc = React.lazy(() => import("../CpManagement/EditCpKyc"));
const ChartList = React.lazy(() => import("../ChartManagement/ChartList"));
const ChartManagement = React.lazy(() =>
  import("../ChartManagement/ChartManagement")
);
const EditChart = React.lazy(() => import("../ChartManagement/EditChart"));

const EditStaff = React.lazy(() => import("../Staff/EditStaff"));
const AssignStaff = React.lazy(() => import("../Staff/AssignStaff"));
const UserSupport = React.lazy(() => import("../UserManagement/UserSupport"));
const EditUserManagement = React.lazy(() =>
  import("../UserManagement/EditUserManagement")
);

const ByProduct = React.lazy(() => import("../transactions/ByProduct"));
const ByWallet = React.lazy(() => import("../transactions/ByWallet"));
const ReturnAmount = React.lazy(() => import("../transactions/Return"));
const ByProductManagement = React.lazy(() =>
  import("../transactions/ByProductManagement")
);
const ByWalletManagement = React.lazy(() =>
  import("../transactions/ByWalletManagement")
);
const EditRealEstate = React.lazy(() => import("../Company/EditRealEstate"));
const ViewContact = React.lazy(() => import("../FormData/ViewContact"));
const UpcomingIPOs = React.lazy(() =>
  import("../FormData/UpcomingIPo/UpcomingIPOs")
);
const IpoManagement = React.lazy(() =>
  import("../FormData/UpcomingIPo/IpoManagement")
);
const EditIpoManagement = React.lazy(() =>
  import("../FormData/UpcomingIPo/EditIpoManagement")
);
const KycList = React.lazy(() => import("../Kyc/KycList"));
const KycDetails = React.lazy(() => import("../Kyc/KycDetails"));
const Equities = React.lazy(() => import("../Company/Equities"));
const RealEstate = React.lazy(() => import("../Company/RealEstate"));
const EquityManagment = React.lazy(() =>
  import("../Company/EquitiesManagment")
);

const Dashboard = React.lazy(() => import("../Dashboard/Dashboard"));
//Blog
const Blog = React.lazy(() => import("../Blog/Blog"));
const BlogManagement = React.lazy(() => import("../Blog/BlogManagement"));
const EditBlog = React.lazy(() => import("../Blog/EditBlog"));

//FormData
const Buy = React.lazy(() => import("../FormData/Buy"));
const Download = React.lazy(() => import("../FormData/Download"));
const Sell = React.lazy(() => import("../FormData/Sell"));
const Contact = React.lazy(() => import("../FormData/Contact"));
const NewsLetter = React.lazy(() => import("../FormData/NewsLetter"));
const Partner = React.lazy(() => import("../FormData/Partner"));
const Inthenews = React.lazy(() => import("../FormData/Inthenews/Inthenews"));
const AddInthenews = React.lazy(() =>
  import("../FormData/Inthenews/AddInthenews")
);
const EditInthenews = React.lazy(() =>
  import("../FormData/Inthenews/EditInthenews")
);
const Document = React.lazy(() => import("../document/Document"));
const DocumentManagment = React.lazy(() =>
  import("../document/DocumentManagment")
);
const EditDocument = React.lazy(() => import("../document/EditDocument"));
//Job and Staff
const Staff = React.lazy(() => import("../Staff/Staff"));
const JobApplication = React.lazy(() => import("../FormData/JobApplication"));

//Header
const Header = React.lazy(() => import("../Header/Header"));

const AppContent = () => {
  const [roledata, setRoleData] = useState([]);
  const adminToken = localStorage.getItem("token");




  const fetchData = async () => {
    try {
      const response = await adminAxiosInstance.get(`/auth/checkPermissions`, {
        headers: { Authorization: `Bearer ${adminToken}` },
      });
      // const accessData = response.data.data?.adminRoles?.Access || [];
      if(response?.data?.data)
      {

        setRoleData(response?.data?.data?.adminRoles?.Access );
      }
      // setTimeout(() => {
        
      // }, 1000);
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    try {
      if (token) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  const isAuth = isAuthenticated();

  // console.log(roledata,"getparmiison")
  return (
    <CContainer lg>
      <Suspense
        fallback={
          <>
            <div className="loader-content">
              <CSpinner color="secondary" />
            </div>
          </>
        }
      >
        <Routes>
          {/* Dashboard */}
          {/* <Route element={<ProtectedRoutes />}> */}
{isAuth ?  <Route path="/dashboard" element={<Dashboard />} />  :  <Route
              path="/"
              element={<Navigate replace to="/admin/login" />}
            />}
            {/* <Route path="/dashboard" element={<Dashboard />} />             */}
            <Route path="/admin-edit" element={<AdminEdit />} />
            <Route
              path="/"
              element={<Navigate replace to="/admin/dashboard" />}
            />
            <Route
              path="*"
              element={<Navigate replace to="/admin/dashboard" />}
            />

            {/* Blog  */}
            <Route path="/" element={<Main moduleType="Blog" />} >
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/blogmanagement" element={<BlogManagement />} />
            <Route path="/blog/blogmanagement/:id" element={<EditBlog />} />
            </Route>


            {/* Roles */}
            <Route path="/" element={<Main moduleType="roles" />} >
            <Route path="/roles" element={<Roles />} />
            <Route path="/roles/addroles" element={<RolesAdd />} />
            <Route path="/roles/editRoles/:id" element={<EditRoles />} />
            </Route>

            {/* employee managament */}
            <Route path="/" element={<Main moduleType="Employee Management" />} >
            <Route path="/employeemanagement" element={<Employeemanage />} />
            <Route path="/employeemanagement/addemployee" element={<Employeeadd />} />
            <Route path="/employeemanagement/editemployee/:id" element={<EditEmployeemanage />} />
            </Route>

            {/* FormData */}

            <Route path="/formdata/viewcontact/:id" element={<ViewContact />} />

            
            <Route path="/" element={<Main moduleType="Buy" />} >
            <Route path="/formdata/buy" element={<Buy />} /> 
            </Route>

            <Route path="/" element={<Main moduleType="Download" />} >
            <Route path="/formdata/download" element={<Download />} />             
            </Route>

            <Route path="/" element={<Main moduleType="Sell" />} >
            <Route path="/formdata/sell" element={<Sell />} />
            </Route>
            <Route path="/formdata/sell/:id" element={<ViewSell />} />


            
            <Route path="/" element={<Main moduleType="Watchlist" />} >
            <Route path="/formdata/watchlist" element={<TrendingStocks />} />
            </Route>

            <Route path="/formdata/bid-now" element={<BidNow />} />
            <Route path="/upcoming-ipos" element={<UpcomingIPOs />} />
            <Route
              path="/upcoming-ipos/edit-ipos/:id"
              element={<EditIpoManagement />}
            />
            <Route path="/upcoming-ipos/add-ipos" element={<IpoManagement />} />

            <Route path="/" element={<Main moduleType="Contact" />} >    
            <Route path="/formdata/contact" element={<Contact />} />
            </Route>


            <Route path="/formdata/user-inquiry" element={<UserInquiries />} />
            <Route path="/formdata/newsletter" element={<NewsLetter />} />
            <Route path="/formdata/partner" element={<Partner />} />

            
            <Route path="/" element={<Main moduleType="In The News" />} >    
            <Route path="/formdata/inthenews" element={<Inthenews />} />
            </Route>


            <Route
              path="/formdata/inthenews/addinthenews"
              element={<AddInthenews />}
              />
            <Route path="/formdata/inthenews/:id" element={<EditInthenews />} />
            <Route path="/formdata/assetapprove" element={<AssetsApprove />} />



            <Route path="/" element={<Main moduleType="Job Application" />} >    
              <Route
                path="/formdata/job-application" element={<JobApplication />}
                />
                </Route>
        



            {/* Staff Management  */}
            <Route path="/" element={<Main moduleType="Staff" />} >    
            <Route path="/staff" element={<Staff />} />
            </Route>


            <Route
              path="/staff/staffmanagement"
              element={<StaffManagement />}
            />
            <Route path="/staff/staffmanagement/:id" element={<EditStaff />} />
            <Route
              path="/staff/staffmanagement/assign/:id"
              element={<AssignStaff />}
            />

            {/* User Management */}

            <Route path="/" element={<Main moduleType="Support" />} >    
            <Route path="/usersupport" element={<UserSupport />} />
            </Route>
            
            <Route path="/" element={<Main moduleType="User" />} >    
            <Route path="/userlist" element={<UserList />} />
            </Route>


            <Route path="/" element={<Main moduleType="Otp" />} >    
            <Route path="/otplist" element={<OtpList />} />
            </Route>

            <Route
              path="/userlist/editusermanagement/:id"
              element={<EditUserManagement />}
            />
            <Route
              path="/userlist/usermanagement"
              element={<UserManagement />}
            />
            {/* Cp Management */}
            <Route path="/" element={<Main moduleType="Cp List" />} >    
            <Route path="/cplist" element={<CpList />} />
            </Route>

            
            <Route path="/" element={<Main moduleType="Cp Kyc" />} >    
            <Route path="/cpkyc" element={<CpKyc />} />
            </Route>

            <Route path="/cplist/cpmanagement" element={<CpManagement />} />
            <Route path="/cpsupport" element={<CpSupport />} />
            <Route path="/cpkyc/cpkycdetails/:id" element={<CpKycDetails />} />
            <Route path="/cpkyc/editcpkyc/:id" element={<EditCpKyc />} />
            <Route
              path="/cplist/editcpmanagement/:id"
              element={<EditCpManagement />}
              />
            {/* chart management */}

            <Route path="/" element={<Main moduleType="Chart List" />} >    
            <Route path="/chartlist" element={<ChartList />} />
            </Route>


            <Route
              path="/chartlist/chartmanagement"
              element={<ChartManagement />}
              />
            <Route path="/chartlist/editchart/:id" element={<EditChart />} />
            {/* Unlisted Comanies */}


            <Route path="/" element={<Main moduleType="Equity" />} >    
            <Route
              path="/equity-crowdfunding/equities"
              element={<Equities />}
              />
              </Route>


            <Route path="/" element={<Main moduleType="Real Estate" />} >    
            <Route
              path="/equity-crowdfunding/real-estate"
              element={<RealEstate />}
              />
              </Route>


            <Route
              path="/equity-crowdfunding/real-estate/:id"
              element={<EditRealEstate />}
            />
            <Route
              path="/equity-crowdfunding/real-estate/add-real-estate"
              element={<RealEstateManagment />}
            />
            <Route
              path="/equity-crowdfunding/equity/add-equity"
              element={<EquityManagment />}
            />
            <Route
              path="/equity-crowdfunding/real-estate/add-equity"
              element={<EquityManagment />}
            />
            <Route path="/property-edit/:id" element={<EquityEdit />} />

            {/* transactions  */}


           <Route path="/" element={<Main moduleType="By Product" />} >    
            <Route path="/by-product" element={<ByProduct />} />
            </Route>


            <Route
              path="/by-product/by-productmanagment"
              element={<ByProductManagement />}
            />
            <Route path="/by-product/:id" element={<EditByProduct />} />


           <Route path="/" element={<Main moduleType="Return" />} >    
            <Route path="/return" element={<ReturnAmount />} />
            </Route>

            <Route path="/" element={<Main moduleType="By Wallet" />} >    
            <Route path="/by-wallet" element={<ByWallet />} />
            </Route>
            <Route
              path="by-wallet/by-walletmanagment"
              element={<ByWalletManagement />}
              />
            <Route path="/by-wallet/:id" element={<EditByWallet />} />

            <Route path="/" element={<Main moduleType="document" />} >  
                 <Route path="/document" element={<Document />} />
              </Route>
            <Route
              path="/document/documentmanagment"
              element={<DocumentManagment />}
            />
            <Route
              path="/document/editdocument/:id"
              element={<EditDocument />}
            />
            {/* KYC  */}
            {/* <Route path="/kyc/kyclist" element={<KycList />} />
          <Route path="/kyc/kycdetails/:id" element={<KycDetails />} /> */}

            {/* FAQ and Glossary */}
            {/* <Route path="/" element={<Main moduleType="FAQs" />} >  
            <Route path="/faq" element={<Faq />} />
              </Route>  */}
              
{/*               
              <Route path="/" element={<Main moduleType="document" />} >  
            <Route path="/glossary" element={<Glossary />} />
              </Route> */}

              

            {/* Headers */}
              <Route path="/" element={<Main moduleType="FAQS" />} >  
            <Route path="/header/:id" element={<Header />} />
              </Route>
          {/* </Route> */}
        </Routes>
      </Suspense>
    </CContainer>
  );
};

export default React.memo(AppContent);
