import { cilGroup } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CCol, CFormRange, CRow } from "@coreui/react";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addUserSchema } from "../../../common/Validation";
import adminAxiosInstance, {
  commonAxiosInstance,
  imageAxiosInstance,
} from "../../../config";
import { ErrorImage } from "../../assets";
import SweetAlert from "../../../helper/SweetAlert";
import PhoneInput from "react-phone-input-2";
const UserManagement = () => {
  const navigate = useNavigate();
  const [imageData, setImageData] = useState();
  // const [content, setContent] = useState();
  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState();
  const [url, setUrl] = useState(ErrorImage);
  const [employeMentStatus, setEmployeMentStatus] = useState("");
  const [howHearAboutUs, setHowHearAboutUs] = useState("");
  const [userTypeArray, setUserTypeArray] = useState([]);
  const [checkRel, setCheckRel] = useState(false);
  const [checkEquity, setCheckEquity] = useState(false);
  // const [addressLink, setAddressLink] = useState("");
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [greenUrl, setGreenUrl] = useState("");
  const [fatcaUrl, setFatcaUrl] = useState("");
  const [residenceUrl, setResidenceUrl] = useState("");
  const [fatcaQuesiton, setFatcaQuesiton] = useState([
    {
      isFav: false,
      label: "Found guilty of any criminal offence.",
    },
    {
      isFav: false,
      label:
        "A subject of any fine in a civil proceeding of fraud, misfeasance, or other misconduct.",
    },
    {
      isFav: false,
      label:
        "The subject of a judgement or agreed settlement in a civil proceeding exceeding a sum equivalent to USD 10,000/- or more.",
    },
    {
      isFav: false,
      label:
        "Disqualified from acting as a director or taking part in the management of a company",
    },
    {
      isFav: false,
      label:
        "Bankrupt or the director or a person concerned in the management of a company which has gone in liquidation or administration.",
    },
  ]);
  const uploadFile = async (e) => {
    let file = e.target.files[0];
    console.log("file====>", file);
    if (file !== undefined) {
      console.log("dbdhcbdcdbhcbdhcbh0", file);
      // setLoading(true);
      var formdata = new FormData();
      formdata.append("multi-files", file);
      console.log("formdata===>", formdata);
      try {
        const response = await imageAxiosInstance.post("/upload", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("respons=====dede==>", response);
        setImageData(response.data?.data[0]?.mediaLink);
        // setLoading(false);
      } catch (error) {
        // setLoading(false);
        console.log("error====>", error);
      }
    }
  };
  const handleLabel = (e, index) => {
    console.log("eeeeeee", e.target.value);
    let abc = [...fatcaQuesiton];
    abc[index].isFav = e.target.value == "false" ? true : false;
    console.log("acacacac-->", abc);
    setFatcaQuesiton(abc);
  };
  const uploadFileTwo = async (e, values) => {
    let file = e.target.files[0];
    console.log("file====>", file);
    if (file !== undefined) {
      console.log("dbdhcbdcdbhcbdhcbh0", file);
      // setLoading(true);
      var formdata = new FormData();
      formdata.append("multi-files", file);
      console.log("formdata===>", formdata);
      try {
        const response = await imageAxiosInstance.post("/upload", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("respons=====dede==>", response);
        setResidenceUrl(response.data?.data[0]?.mediaLink);
        // setLoading(false);
      } catch (error) {
        // setLoading(false);
        console.log("error====>", error);
      }
    }
  };
  const uploadFileThree = async (e) => {
    let file = e.target.files[0];
    console.log("file====>", file);
    if (file !== undefined) {
      console.log("dbdhcbdcdbhcbdhcbh0", file);
      // setLoading(true);
      var formdata = new FormData();
      formdata.append("multi-files", file);
      console.log("formdata===>", formdata);
      try {
        const response = await imageAxiosInstance.post("/upload", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("respons=====dede==>", response);
        setGreenUrl(response.data?.data[0]?.mediaLink);
        // setLoading(false);
      } catch (error) {
        // setLoading(false);
        console.log("error====>", error);
      }
    }
  };
  const uploadFileFour = async (e) => {
    let file = e.target.files[0];
    console.log("file====>", file);
    if (file !== undefined) {
      console.log("dbdhcbdcdbhcbdhcbh0", file);
      // setLoading(true);
      var formdata = new FormData();
      formdata.append("multi-files", file);
      console.log("formdata===>", formdata);
      try {
        const response = await imageAxiosInstance.post("/upload", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("respons=====dede==>", response);
        setFatcaUrl(response.data?.data[0]?.mediaLink);
        // setLoading(false);
      } catch (error) {
        // setLoading(false);
        console.log("error====>", error);
      }
    }
  };

  // const addressProof = async (e) => {
  //   let file = e.target.files[0];
  //   // setIsFileChanged(true);
  //   // setImageLoading(true);
  //   var formdata = new FormData();
  //   formdata.append("multi-files", file);
  //   try {
  //     const response = await imageAxiosInstance.post("/upload", formdata, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });
  //     console.log("img---respon-->", response);
  //     setAddressLink(response?.data?.data[0].mediaLink);
  //   } catch (error) {
  //     console.log("img==>upload---->error", error);
  //   }
  // };
  const countryData = async () => {
    try {
      const response = await commonAxiosInstance.get("/countryList");
      console.log(response);
      setCountryList(response?.data?.data);
    } catch (error) {
      console.log("error--->", error);
    }
  };
  console.log("country---list---->", countryList);

  const onCheck = (e) => {
    let { value, checked } = e.target;
    console.log("cdssd", checked, value);
    if (checked) {
      setUserTypeArray([...userTypeArray, value]);
    } else {
      setUserTypeArray(userTypeArray.filter((e) => e !== value));
    }
  };
  console.log("userTypeArray", userTypeArray);

  const handleChangeInvest = (values, index, e) => {
    let abc = [...values.approximateBreakupNetWorth];
    abc[index].value = e;

    console.log("values--->", abc);
    let initialValues = 0;
    // let sunTotal = abc.reduce(
    //   (previousValue, currentValue) => previousValue.value + currentValue.value,
    //   initialValues
    // );
    // let total = 0;
    abc.map((item) => {
      // item.value = Number(item?.value);
      // if (typeof item?.value == number) {
      initialValues += Number(item?.value);
      // }
    });

    // console.log("ssssss", previousValue, "kkkkkkkkkkkk", currentValue);
    console.log("sunTottal--->", initialValues);
    setTotalInvestment(initialValues);
  };

  const handleFormSubmit = async (values) => {
    console.log("values===>", values);

    values.profileImage = imageData;
    // alert(values);
    values.userType = userTypeArray;
    // values.addressProof = addressProof;
    delete values.featured_image;
    if (employeMentStatus == "Student" || employeMentStatus == "Employed") {
      delete values.organizationLocation;
      delete values.organizationName;
      delete values.annualIncomeBracket;
      delete values.employmentIndustry;
      delete values.jobTitle;
    }
    if (
      !howHearAboutUs == "Employee" ||
      !howHearAboutUs == "Referral Partner" ||
      !howHearAboutUs == "Business Associate"
    ) {
      delete values.code;
      delete values.name;
    } else {
      delete values.otherInput;
    }
    if (values.declaration === "Yes") {
      values.declaration = "true";
      values.FATCAdocument = fatcaUrl;
      fatcaQuesiton.forEach((element, index) => {
        if (element.isFav == true) {
          values.FATCAcondition.push({ condition: element.label });
        }
      });
    } else {
      values.declaration = "false";
    }
    if (values.citizenOfUSA == true) {
      values.citizenOfUSADocument = greenUrl;
    }

    if (
      values.countryOfCurrentResidence.name != "others, please specify_____"
    ) {
      values.countryOfCurrentResidence.residence = "";
    }

    values.countryOfCurrentResidence.document = residenceUrl;

    try {
      console.log("form-value===>", values);
      const response = await adminAxiosInstance.post("/userManage/add", {
        ...values,
      });
      console.log("response===>", response);
      SweetAlert({
        icon: "success",
        text: response?.data?.message || "you data has Submit",
        title: "Success",
        timer: "1500",
      });
      navigate("/admin/userlist");
    } catch (err) {
      console.log("err ==>", err);
      SweetAlert({
        icon: "error",
        text: err?.response?.data?.message || "something Went Wrong!",
        title: "Warning!",
      });
    }
  };
  useEffect(() => {
    if (imageData !== undefined) {
      setUrl(imageData);
      setLoading(false);
    }
  }, [imageData]);
  useEffect(() => {
    countryData();
  }, []);
  console.log("image--data--->", imageData);

  return (
    <>
      <div className="d-flex">
        <CIcon icon={cilGroup} size="xxl" />
        <h3>User Details</h3>
      </div>
      <div>
        <Link className="text-decoration-none text-white" to="/admin/userlist">
          <button className="text-white btn btn-info">Go Back</button>
        </Link>
      </div>
      <div>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            dateOfBirth: "",
            nationality: "",
            declaration: "",
            // referralCode: "",
            state: "",
            postalCode: "",
            FATCAdocument: "",
            FATCAcondition: [],
            completedStep: "",
            jobTitle: "",
            employmentIndustry: "",
            userType: "",
            businessRelationship: "",
            city: "",
            // onfidoVerification: "",

            profileImage: "",
            // onfidoApplicantId: "",
            organizationLocation: "",
            organizationName: "",
            currentNetAssets: "",
            gender: "",
            annualIncomeBracket: "",
            address: "",
            userUniqueCode: "",
            featured_image: "",
            // addressProof: "",
            whatsAppNo: "",
            AlternateNumber: "",
            userState: "",
            wealthSource: "",
            reasonForTransaction: "",
            historyOfInvesting: "",
            desiredRiskAppetite: "",
            citizenOfUSADocument: "",
            citizenOfUSA: false,
            countryOfCurrentResidence: {
              name: "",
              document: "",
              residence: "",
            },
            // residence: "",
            approximateBreakupNetWorth: [
              {
                name: "Bank deposits",
                value: 0,
              },
              {
                name: "Stock market investments",
                value: 0,
              },
              {
                name: "Other Financial instruments",
                value: 0,
              },
              {
                name: "Real estate",
                value: 0,
              },
              {
                name: "Investment in business",
                value: 0,
              },
              {
                name: "",
                value: 0,
              },
            ],
          }}
          onSubmit={handleFormSubmit}
          validationSchema={addUserSchema}
        >
          {({
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            isSubmitting,
            errors,
            touched,
          }) => (
            <div className="border-top border-info border-3 bg-white mt-3 p-2">
              <h4 className="fw-normal">Enter User Details</h4>
              <Form>
                <CRow>
                  <CCol sm={5}>
                    <div>
                      <div className="form-group mt-3">
                        <label htmlFor="name" className="fw-bold mb-2">
                          First Name
                        </label>
                        <input
                          type="text"
                          className={`form-control${
                            errors.firstName && touched.firstName
                              ? " is-invalid"
                              : ""
                          }`}
                          name="firstName"
                          id="fullName"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="text-danger">
                        <ErrorMessage name="firstName" />
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="name" className="fw-bold mb-2">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className={`form-control${
                            errors.lastName && touched.lastName
                              ? " is-invalid"
                              : ""
                          }`}
                          name="lastName"
                          id="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="text-danger">
                        <ErrorMessage name="lastName" />
                      </div>
                      <div className="mt-5">
                        <PhoneInput
                          international
                          defaultCountry={"in"}
                          country="in"
                          // defaultCountry="RU"
                          inputProps={{
                            name: "phone Number",
                            required: true,
                          }}
                          countryCallingCodeEditable={true}
                          name="mobile"
                          value={values.mobile}
                          onChange={(e) => {
                            setFieldValue("mobile", e);
                            console.log("eee-->", e);
                          }}
                          onBlur={handleBlur}
                        />
                        {/* <label htmlFor="mobile" className="fw-bold mb-2">
                          Mobile No.
                        </label>
                        <input
                          type="text"
                          className={`form-control${
                            errors.mobile && touched.mobile ? " is-invalid" : ""
                          }`}
                          name="mobile"
                          id="mobile"
                          value={values.mobile}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        /> */}
                      </div>
                      <div className="text-danger">
                        <ErrorMessage name="mobile" />
                      </div>
                      <div className="mt-5">
                        <PhoneInput
                          international
                          defaultCountry={"in"}
                          country="in"
                          // defaultCountry="RU"
                          inputProps={{
                            name: "",
                            required: true,
                          }}
                          specialLabel="WhatsApp Number"
                          countryCallingCodeEditable={true}
                          name="whatsAppNo"
                          value={values.whatsAppNo}
                          onChange={(e) => {
                            setFieldValue("whatsAppNo", e);
                            console.log("eee-->", e);
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="mt-5">
                        <PhoneInput
                          international
                          defaultCountry={"in"}
                          country="in"
                          // defaultCountry="RU"
                          inputProps={{
                            name: "",
                            required: true,
                          }}
                          specialLabel="Alternate Number"
                          countryCallingCodeEditable={true}
                          name="AlternateNumber"
                          value={values.AlternateNumber}
                          onChange={(e) => {
                            setFieldValue("AlternateNumber", e);
                            console.log("eee-->", e);
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                      {/* <div className="mt-5">
                        <PhoneInput
                          international
                          defaultCountry={"in"}
                          country="in"
                          // defaultCountry="RU"
                          inputProps={{
                            name: "",
                            required: true,
                          }}
                          specialLabel="Referral Number"
                          countryCallingCodeEditable={true}
                          name="referralCode"
                          value={values.referralCode}
                          onChange={(e) => {
                            setFieldValue("referralCode", e);
                            console.log("eee-->", e);
                          }}
                          onBlur={handleBlur}
                        />
                      </div> */}
                      <div className="form-group mt-5">
                        <label htmlFor="email" className="fw-bold mb-2">
                          Email
                        </label>
                        <input
                          type="text"
                          className={`form-control${
                            errors.email && touched.email ? " is-invalid" : ""
                          }`}
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="text-danger">
                        <ErrorMessage name="email" />
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="name" className="fw-bold mb-2">
                          Registe & Type
                        </label>
                        <div>
                          <input
                            type="checkbox"
                            // className={`form-control`}

                            id="userType"
                            value={"Real Estate"}
                            checked={checkRel}
                            onClick={(e) => {
                              onCheck(e);
                              setCheckRel(e.target.checked);
                              console.log("vvvvv", e.target.checked);
                            }}
                          />
                          <label>Real Estate</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            // className={`form-control`}
                            // name="userType[0]"
                            id="userType"
                            checked={checkEquity}
                            value={"Equity"}
                            // checked={}
                            onClick={(e) => {
                              onCheck(e);
                              setCheckEquity(e.target.checked);
                            }}
                            // onBlur={handleBlur}
                          />
                          <label>Equity</label>
                        </div>
                      </div>
                      <CCol xl={12} lg={12}>
                        {/* <div className="border-1">
                          <label
                            htmlFor="addressProof"
                            className="fw-bold mb-2"
                          >
                            Address Proof
                          </label>
                          <input
                            // placeholder="Enter Stock Name"
                            type="file"
                            name="addressProof"
                            value={values.addressProof}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              addressProof(e);
                              setFieldValue("addressProof", e.target.value);
                              handleChange(e);
                            }}
                            className="form-control"
                          />
                          <div className="mt-2 mb-3">
                            <label>Link :-</label>
                            <a href={addressLink} target="_blank">
                              {addressLink}
                            </a>
                          </div>
                        </div> */}
                      </CCol>
                      <div className="form-group mt-3">
                        <label
                          htmlFor="userUniqueCode"
                          className="fw-bold mb-2"
                        >
                          User Unique Code
                        </label>
                        <input
                          type="text"
                          className={`form-control`}
                          name="userUniqueCode"
                          id="userUniqueCode"
                          value={values.userUniqueCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled
                        />
                      </div>
                      {/* <div className="form-group mt-3">
                        <label
                          htmlFor="onfidoApplicantId"
                          className="fw-bold mb-2"
                        >
                          Onfido Applicant Id
                        </label>
                        <input
                          type="text"
                          className={`form-control`}
                          name="onfidoApplicantId"
                          disabled
                          id="onfidoApplicantId"
                          value={values.onfidoApplicantId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div> */}
                      {/* <div className="form-group mt-5">
                        <label
                          htmlFor="onfidoVerification"
                          className="fw-bold mb-2"
                        >
                          Onfido Verification
                        </label>

                        <select
                          // defaultValue={item?.status}
                          id="userState"
                          className={`form-select`}
                          name="onfidoVerification"
                          value={values.onfidoVerification}
                          onChange={(e) => {
                            setFieldValue("onfidoVerification", e.target.value);
                            // handleChange(e);
                            console.log(e.target.value);
                          }}
                          // disabled
                          onBlur={handleBlur}
                        >
                          <option value="">Choose</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </div> */}
                      <div className="form-group mt-3">
                        <label htmlFor="completedStep" className="fw-bold mb-2">
                          Completed Step
                        </label>
                        <input
                          type="text"
                          className={`form-control`}
                          name="completedStep"
                          id="completedStep"
                          value={values.completedStep}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // disabled
                        />
                      </div>
                      <div className="form-group mt-5">
                        <label htmlFor="country" className="fw-bold mb-2">
                          Nationality
                        </label>
                        <select
                          type="text"
                          className={`form-select${
                            errors.nationality && touched.nationality
                              ? " is-invalid"
                              : ""
                          }`}
                          id="country"
                          name="nationality"
                          value={values.nationality}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value=""> Choose</option>
                          {countryList?.map((item, i) => (
                            <option key={i} value={item[`Alpha-3 code`]}>
                              {/* {console.log("item", item[`Alpha-3 code`])} */}
                              {item?.Country}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="text-danger">
                        <ErrorMessage name="nationality" />
                      </div>
                      <div className="form-group mt-5">
                        <label
                          htmlFor="address"
                          className="form-label fw-label"
                        >
                          Address
                        </label>
                        <textarea
                          rows="4"
                          cols="50"
                          type="text"
                          id="address"
                          name="address"
                          placeholder="Address"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control${
                            errors.address && touched.address
                              ? " is-invalid"
                              : ""
                          }`}
                        ></textarea>
                      </div>
                      <div className="text-danger">
                        <ErrorMessage name="address" />
                      </div>
                      <div className="form-group mt-5">
                        <label htmlFor="postalCode" className="fw-bold mb-2">
                          Postal Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="postalCode"
                          name="postalCode"
                          value={values.postalCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="form-group mt-5">
                        <label htmlFor="city" className="fw-bold mb-2">
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {/* <div className="text-danger">
                        <ErrorMessage name="city" />
                      </div> */}
                      <div className="form-group mt-5">
                        <label htmlFor="state" className="fw-bold mb-2">
                          State
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="state"
                          name="state"
                          value={values.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="form-group mt-5">
                        <label htmlFor="declaration" className="fw-bold mb-2">
                          Country of Current Residence Document:
                        </label>

                        <select
                          // defaultValue={item?.status}
                          id="userState"
                          className={`form-select`}
                          name="countryOfCurrentResidence.name"
                          value={values.countryOfCurrentResidence.name}
                          onChange={(e) => {
                            setFieldValue(
                              "countryOfCurrentResidence.name",
                              e.target.value
                            );
                            // handleChange(e);
                            console.log(e.target.value);
                          }}
                          onBlur={handleBlur}
                        >
                          <option value={""}>Choose...</option>
                          <option value="residence visa">Residence visa</option>
                          <option value="any government issued local ID,">
                            Any government issued local ID,
                          </option>
                          <option value="others, please specify_____">
                            Others, please specify_____
                          </option>
                        </select>
                      </div>
                      {values.countryOfCurrentResidence.name ==
                      "others, please specify_____" ? (
                        <div className="my-2">
                          <input
                            type="text"
                            className="form-control"
                            id="resent"
                            placeholder="Field Name"
                            name="countryOfCurrentResidence.residence"
                            value={values.countryOfCurrentResidence.residence}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      {values.countryOfCurrentResidence.name != "" ? (
                        <div className="mt-3">
                          <div className="text-center">
                            <img
                              className="img-fluid max_img_view_admin"
                              src={residenceUrl ? residenceUrl : ErrorImage}
                              alt="blog"
                            />
                          </div>
                          <div className="form-group mt-3">
                            <label
                              htmlFor="featured_image"
                              className="fw-bold mb-2"
                            >
                              Residence Image
                            </label>
                            <input
                              type="file"
                              // name="featured_image"
                              className="form-control"
                              id="featured_image"
                              accept="image/*"
                              // value={values.featured_image}
                              // onBlur={handleBlur}
                              onChange={(e) => {
                                uploadFileTwo(e, values);
                                // setFieldValue("featured_image", e.target.value);
                                // uploadFile(e);
                                // handleChange(e);
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="mt-3 d-flex">
                        <input
                          type="checkbox"
                          // className={`form-control`}
                          name="citizenOfUSA"
                          id="userType"
                          value={values.citizenOfUSA}
                          // checked={checkRel}
                          onClick={(e) => {
                            // handleChange(e);
                            setFieldValue(
                              "citizenOfUSA",
                              Boolean(e.target.checked)
                            );
                            // onCheck(e);
                            // setCheckRel(e.target.checked);
                            console.log("vvvvv", e.target.checked);
                          }}
                        />
                        <label className="me-3">
                          A citizen/green card holder/or resident of United
                          States of America
                        </label>
                      </div>
                      {values.citizenOfUSA ? (
                        <div className="mt-3">
                          <div className="text-center">
                            <img
                              className="img-fluid max_img_view_admin"
                              src={greenUrl ? greenUrl : ErrorImage}
                              alt="blog"
                            />
                          </div>
                          <div className="form-group mt-3">
                            <label
                              htmlFor="featured_image"
                              className="fw-bold mb-2"
                            >
                              Residence Image
                            </label>
                            <input
                              type="file"
                              // name="featured_image"
                              className="form-control"
                              id="featured_image"
                              accept="image/*"
                              // value={values.featured_image}
                              // onBlur={handleBlur}
                              onChange={(e) => {
                                uploadFileThree(e);
                                // setFieldValue("featured_image", e.target.value);
                                // uploadFile(e);
                                // handleChange(e);
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="form-group mt-5">
                        <label htmlFor="declaration" className="fw-bold mb-2">
                          Declaration
                        </label>

                        <select
                          // defaultValue={item?.status}
                          id="userState"
                          className={`form-select`}
                          name="declaration"
                          value={values.declaration}
                          onChange={(e) => {
                            setFieldValue("declaration", e.target.value);
                            // handleChange(e);
                            console.log(e.target.value);
                          }}
                          onBlur={handleBlur}
                        >
                          <option value="">Choose</option>
                          <option value={"Yes"}>Yes</option>
                          <option value={"No"}>No</option>
                        </select>
                      </div>
                      {console.log("tttttttt", values.declaration)}
                      {values.declaration == "Yes" ? (
                        <div className="form-group mt-5">
                          {console.log("sssss", values.declaration)}
                          <div className="mt-3">
                            <div className="text-center">
                              <img
                                className="img-fluid max_img_view_admin"
                                src={fatcaUrl ? fatcaUrl : ErrorImage}
                                alt="blog"
                              />
                            </div>
                            <div className="form-group mt-3">
                              <label
                                htmlFor="featured_image"
                                className="fw-bold mb-2"
                              >
                                Image
                              </label>
                              <input
                                type="file"
                                // name="featured_image"
                                className="form-control"
                                id="featured_image"
                                accept="image/*"
                                // value={values.featured_image}
                                // onBlur={handleBlur}
                                onChange={(e) => {
                                  uploadFileFour(e);
                                  // setFieldValue("featured_image", e.target.value);
                                  // uploadFile(e);
                                  // handleChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="my-3">
                            <label
                              htmlFor="declaration"
                              className="fw-bold mb-2"
                            >
                              Have you ever been: (If the answer to any of above
                              is “Yes”, kindly give details)
                            </label>
                            {fatcaQuesiton.map((item, index) => (
                              <div className="my-2 d-flex" key={index}>
                                <input
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#TermsCondition"
                                  // isChecked={terms}
                                  className="global-font-body"
                                  type={"checkbox"}
                                  value={item?.isFav}
                                  onChange={(e) => handleLabel(e, index)}
                                  // onBlur={handleBlur}
                                />
                                <div className="px-2">
                                  {" "}
                                  <label>{item.label}</label>
                                </div>

                                {/* </input> */}
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </CCol>
                  <CCol sm={5} className="mt-5">
                    <div className="text-center">
                      <img
                        className="img-fluid max_img_view_admin"
                        src={imageData ? imageData : ErrorImage}
                        alt="blog"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="featured_image" className="fw-bold mb-2">
                        Profile Image
                      </label>
                      <input
                        type="file"
                        name="featured_image"
                        className="form-control"
                        id="featured_image"
                        accept="image/*"
                        value={values.featured_image}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("featured_image", e.target.value);
                          uploadFile(e);
                          handleChange(e);
                        }}
                      />
                    </div>
                    <div className="form-group mt-5">
                      <label htmlFor="mobile" className="fw-bold mb-2">
                        Gender
                      </label>

                      <select
                        // defaultValue={item?.status}
                        className={`form-select${
                          errors.gender && touched.gender ? " is-invalid" : ""
                        }`}
                        name="gender"
                        value={values.gender}
                        id="transferShare"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Choose</option>
                        <option>Male</option>
                        <option>Female</option>
                      </select>
                    </div>
                    <div className="text-danger">
                      <ErrorMessage name="gender" />
                    </div>
                    <div className="form-group mt-5">
                      <label htmlFor="date" className="fw-bold mb-2">
                        Date Of Birth
                      </label>
                      <input
                        type="date"
                        className={`form-control${
                          errors.dateOfBirth && touched.dateOfBirth
                            ? " is-invalid"
                            : ""
                        }`}
                        id="Date"
                        name="dateOfBirth"
                        value={values.dateOfBirth}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="text-danger">
                      <ErrorMessage name="dateOfBirth" />
                    </div>
                    <div className="form-group mt-5">
                      <label htmlFor="userState" className="fw-bold mb-2">
                        Active
                      </label>

                      <select
                        // defaultValue={item?.status}
                        id="userState"
                        className={`form-select${
                          errors.userState && touched.userState
                            ? " is-invalid"
                            : ""
                        }`}
                        name="userState"
                        value={values.userState}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Choose</option>
                        <option value="true">No</option>
                        <option value="false">Yes</option>
                      </select>
                    </div>
                    <div className="text-danger">
                      <ErrorMessage name="userState" />
                    </div>

                    <div className="form-group mt-5">
                      <label htmlFor="mobile" className="fw-bold mb-2">
                        Employement Status
                      </label>

                      <select
                        // defaultValue={item?.status}
                        className={`form-select${
                          errors.employementStatus && touched.employementStatus
                            ? " is-invalid"
                            : ""
                        }`}
                        name="employementStatus"
                        value={values.employementStatus}
                        id="transferShare"
                        onChange={(e) => {
                          handleChange(e);
                          setEmployeMentStatus(e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        <option value="Choose">Choose</option>
                        <option value={"Student"}>Student</option>
                        <option value={"Self Employed"}>Self Employed</option>
                        <option value={"Employed"}>Employed</option>
                        <option value={"Retired"}>Retired</option>

                        {/* <option>Female</option> */}
                      </select>
                    </div>
                    <div className="text-danger">
                      <ErrorMessage name="employementStatus" />
                    </div>
                    {employeMentStatus == "Self Employed" ||
                    employeMentStatus == "Employed" ? (
                      <>
                        <div className="form-group mt-3">
                          <label
                            htmlFor="organizationLocation"
                            className="fw-bold mb-2"
                          >
                            Organization Location
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="organizationLocation"
                            id="organizationLocation"
                            value={values.organizationLocation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label
                            htmlFor="organizationName"
                            className="fw-bold mb-2"
                          >
                            Organization Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="organizationName"
                            id="organizationName"
                            value={values.organizationName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label htmlFor="jobTitle" className="fw-bold mb-2">
                            What is your Job Title?
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="jobTitle"
                            id="jobTitle"
                            value={values.jobTitle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label htmlFor="jobTitle" className="fw-bold mb-2">
                            What is your Employment Industry?
                          </label>
                          {/* <input
                            type="text"
                            className="form-control"
                            name="jobTitle"
                            id="jobTitle"
                            value={values.jobTitle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          /> */}
                          <select
                            name="employmentIndustry"
                            value={values?.employmentIndustry}
                            onChange={handleChange}
                            className="form-select"
                          >
                            <option value="">
                              What is your Employment Industry?
                            </option>
                            <option value="Education">Education</option>
                            <option value="Energy">Energy</option>
                            <option value="Healthcare">Healthcare</option>
                            <option value="Government">Government</option>
                            <option value="Professional">Professional</option>
                            <option value="Services">Services</option>
                            <option value="Food & Beverage">
                              Food & Beverage
                            </option>
                            <option
                              value="Personal Services
"
                            >
                              Personal Services
                            </option>
                            <option
                              value="Non Profits
"
                            >
                              Non Profits
                            </option>
                            <option value="Technology">Technology</option>
                            <option value="Finance">Finance</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                        <div className="form-group mt-3">
                          <label
                            htmlFor="annualIncomeBracket"
                            className="fw-bold mb-2"
                          >
                            Please select your annual income bracket
                          </label>
                          <select
                            name="annualIncomeBracket"
                            value={values.annualIncomeBracket}
                            onChange={handleChange}
                            className="form-select"
                          >
                            <option value="">
                              Please select your annual income bracket
                            </option>
                            <option value="$0 - $15,000">$0 - $15,000</option>
                            <option value="$15,001 - $50,000">
                              $15,001 - $50,000
                            </option>
                            <option value="$50,001 - $100,000">
                              $50,001 - $100,000
                            </option>
                            <option value="$100,001 - $250,000">
                              $100,001 - $250,000
                            </option>
                            <option value="$250,001 - $500,000">
                              $250,001 - $500,000
                            </option>
                            <option value="$500,001 - $750,000">
                              $500,001 - $750,000
                            </option>
                            <option value="$750,001+">$750,001+</option>
                          </select>
                          {/* <input
                            type="text"
                            className="form-control"
                            name="jobTitle"
                            id="jobTitle"
                            value={values.jobTitle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          /> */}
                        </div>
                        {/* <div></div> */}
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="form-group mt-5">
                      <label htmlFor="wealthSource" className="fw-bold mb-2">
                        Wealth Source
                      </label>

                      <select
                        // defaultValue={item?.status}
                        className={`form-select${
                          errors.wealthSource && touched.wealthSource
                            ? " is-invalid"
                            : ""
                        }`}
                        name="wealthSource"
                        value={values.wealthSource}
                        id="wealthSource"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Choose</option>
                        <option value="Investments">Investments</option>
                        <option value="Winnings">Winnings</option>
                        <option value="Savings from employment earnings">
                          Savings from employment earnings
                        </option>
                        <option value="Inheritance">Inheritance</option>
                        {/* <option>Female</option> */}
                      </select>
                    </div>
                    <div className="text-danger">
                      <ErrorMessage name="wealthSource" />
                    </div>

                    <div className="form-group mt-3">
                      <label
                        htmlFor="businessRelationship"
                        className="fw-bold mb-2"
                      >
                        Intended nature of Business Relationship ?
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="businessRelationship"
                        id="businessRelationship"
                        value={values.businessRelationship}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="form-group mt-5">
                      <label
                        htmlFor="currentNetAssets"
                        className="fw-bold mb-2"
                      >
                        Please select the value of your current net assets
                      </label>

                      <select
                        // defaultValue={item?.status}
                        className={`form-select`}
                        name="currentNetAssets"
                        value={values.currentNetAssets}
                        id="currentNetAssets"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">
                          Please select the value of your current net assets
                        </option>
                        <option value="$0 - $20,000">$0 - $20,000</option>
                        <option value="$20,001 - $50,000">
                          $20,001 - $50,000
                        </option>
                        <option value="$50,001 - $250,000">
                          $50,001 - $250,000
                        </option>
                        <option value="$250,001 - $500,000">
                          $250,001 - $500,000
                        </option>
                        <option value="$500,001 - $750,000">
                          $500,001 - $750,000
                        </option>
                        <option value="$750,001 - $1,000,000">
                          $750,001 - $1,000,000
                        </option>
                        <option value="$1,000,001 - $2,000,000">
                          $1,000,001 - $2,000,000
                        </option>
                        <option value="$2,000,001 - $5,000,000">
                          $2,000,001 - $5,000,000
                        </option>
                        <option value="$5,000,000+">$5,000,000+</option>
                      </select>
                    </div>
                    <div className="form-group mt-5">
                      <label
                        htmlFor="reasonForTransaction"
                        className="fw-bold mb-2"
                      >
                        Reason for the Transaction?
                      </label>

                      <select
                        // defaultValue={item?.status}
                        className={`form-select`}
                        name="reasonForTransaction"
                        value={values.reasonForTransaction}
                        id="reasonForTransaction"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Reason for the Transaction?</option>
                        <option value="Create a passive Income">
                          Create a passive Income
                        </option>
                        <option value="Plan for Retirement">
                          Plan for Retirement
                        </option>
                        <option value="Create an Emergency Fund">
                          Create an Emergency Fund
                        </option>
                        <option value="Wealth Creation">Wealth Creation</option>
                      </select>
                    </div>
                    <div className="form-group mt-5">
                      <label
                        htmlFor="historyOfInvesting"
                        className="fw-bold mb-2"
                      >
                        History of investing in financial markets
                      </label>

                      <select
                        // defaultValue={item?.status}
                        className={`form-select`}
                        name="historyOfInvesting"
                        value={values.historyOfInvesting}
                        id="historyOfInvesting"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value={""}>Choose...</option>
                        <option value="Proficient">Proficient</option>
                        <option value="Average">Average</option>
                        <option value="very little">very little</option>
                      </select>
                    </div>
                    <div className="form-group mt-5">
                      <label
                        htmlFor="desiredRiskAppetite"
                        className="fw-bold mb-2"
                      >
                        Desired Risk appetite for investments
                      </label>

                      <select
                        // defaultValue={item?.status}
                        className={`form-select`}
                        name="desiredRiskAppetite"
                        value={values.desiredRiskAppetite}
                        id="desiredRiskAppetite"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value={""}>Choose...</option>
                        <option value="moderate">Moderate</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                        <option value="Don’t Care"> Don’t Care</option>
                      </select>
                    </div>
                    <div>
                      <label className="fw-bold mt-5">
                        Please enter the approxine breakdown of your net-worth
                      </label>
                      <label className="fw-bold text-muted">
                        The total value must reach 100%
                      </label>
                      {values.approximateBreakupNetWorth.map((item, index) =>
                        index < 5 ? (
                          <div
                            key={index}
                            className="d-flex my-3 justify-content-between"
                          >
                            <CFormRange
                              min="0"
                              max="100"
                              label={item?.name}
                              defaultValue="0"
                              onChange={(e) => {
                                console.log("eeeeeee", e.target.value);
                                handleChangeInvest(
                                  values,
                                  index,
                                  e.target.value
                                );
                              }}
                              id="customRange2"
                            />
                            <span>
                              {values.approximateBreakupNetWorth[index].value}
                            </span>
                          </div>
                        ) : (
                          <></>
                        )
                      )}
                      <div className="d-flex">
                        <div>
                          <p className="global-font-body mb-1 mt-2 me-2 fw-bolder">
                            Others
                          </p>
                          {/* <FormControl variant="floating" id="EmployeeCode"> */}
                          <input
                            type={"text"}
                            placeholder="Please Specify"
                            fontSize={"14px"}
                            name={`approximateBreakupNetWorth[${5}].name`}
                            value={values.approximateBreakupNetWorth[5].name}
                            onChange={(e) => {
                              setFieldValue(
                                `approximateBreakupNetWorth[${5}].name`,
                                e.target.value
                              );
                              // handleChange();
                            }}
                            // onBlur={handleBlur}
                          />
                        </div>
                        <CFormRange
                          min="0"
                          max="100"
                          // label={item?.name}
                          defaultValue="0"
                          onChange={(e) => {
                            console.log("eeeeeee", e.target.value);
                            handleChangeInvest(values, 5, e.target.value);
                          }}
                          id="customRange2"
                        />
                        <span>
                          {values.approximateBreakupNetWorth[5].value}
                        </span>
                      </div>
                    </div>
                    {totalInvestment != 100 ? (
                      <div className="mt-2 text-danger">
                        Total invested values in percentage must be equal to 100
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* <div className="form-group mt-5">
                      <label htmlFor="howHearAboutUs" className="fw-bold mb-2">
                        How Hear About Us
                      </label>
                      <select
                        // defaultValue={item?.status}
                        className={`form-select${
                          errors.howHearAboutUs && touched.howHearAboutUs
                            ? " is-invalid"
                            : ""
                        }`}
                        name="howHearAboutUs"
                        value={values.howHearAboutUs}
                        id="wealthSource"
                        onChange={(e) => {
                          handleChange(e);
                          setHowHearAboutUs(e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        <option value="">Choose</option>
                        <option value="Referral Partner">
                          Referral Partner
                        </option>
                        <option value="Employee">Employee</option>
                        <option value="Business Associate">
                          Business Associate
                        </option>
                        <option value="Other">Other</option>
                      </select>
                    </div> */}
                    {/* <div className="text-danger">
                      <ErrorMessage name="howHearAboutUs" />
                    </div>
                    {howHearAboutUs == "Employee" ||
                    howHearAboutUs == "Referral Partner" ||
                    howHearAboutUs == "Business Associate" ? (
                      <>
                        <div className="form-group mt-5">
                          <label htmlFor="mobile" className="fw-bold mb-2">
                            Code
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="dpId"
                            name="code"
                            value={values.code}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="form-group mt-5">
                          <label htmlFor="name" className="fw-bold mb-2">
                            Name
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="form-group mt-5">
                          <label htmlFor="otherInput" className="fw-bold mb-2">
                            Other Input
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="otherInput"
                            name="otherInput"
                            value={values.otherInput}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </>
                    )} */}

                    {/* <div className="form-group mt-5">
                      <label htmlFor="city" className="fw-bold mb-2">
                        Dp ID
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="dpId"
                        name="dpId"
                        value={values.dpId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="text-danger">
                      <ErrorMessage name="dpId" />
                    </div> */}
                    {/* <div className="form-group mt-5">
                      <label htmlFor="city" className="fw-bold mb-2">
                        Dp Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="dpName"
                        name="dpName"
                        value={values.dpName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="text-danger">
                      <ErrorMessage name="dpName" />
                    </div> */}
                    {/* <div className="form-group mt-5">
                      <label htmlFor="city" className="fw-bold mb-2">
                        Client Id
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="clientId"
                        name="clientId"
                        value={values.clientId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="text-danger">
                      <ErrorMessage name="clientId" />
                    </div> */}
                    {/* <div className="form-group mt-5">
                      <label htmlFor="city" className="fw-bold mb-2">
                        Client Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="clientName"
                        name="clientName"
                        value={values.clientName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="text-danger">
                      <ErrorMessage name="clientName" />
                    </div> */}
                  </CCol>
                  {/* <CRow>
                    <CCol className="mt-5">
                      <div className="text-center">
                        <img
                          className="img-fluid max_img_view_admin"
                          src={urlLink}
                          alt="blog"
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label
                          htmlFor="featured_image"
                          className="fw-bold mb-2"
                        >
                          Aadhaar Front Image
                        </label>
                        <input
                          type="file"
                          name="featured_image"
                          className="form-control"
                          id="featured_image"
                          accept="image/*"
                          onChange={(e) => {}}
                        />
                      </div>
                    </CCol>
                    <CCol className="mt-5">
                      <div className="text-center">
                        <img
                          className="img-fluid max_img_view_admin"
                          alt="blog"
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label
                          htmlFor="featured_image"
                          className="fw-bold mb-2"
                        >
                          Aadhaar Back Image
                        </label>
                        <input
                          type="file"
                          name="featured_image"
                          className="form-control"
                          id="featured_image"
                          accept="image/*"
                          onChange={(e) => {}}
                        />
                      </div>
                    </CCol>
                    <CCol className="mt-5">
                      <div className="text-center">
                        <img
                          className="img-fluid max_img_view_admin"
                          alt="blog"
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label
                          htmlFor="featured_image"
                          className="fw-bold mb-2"
                        >
                          Pan Card Image
                        </label>
                        <input
                          type="file"
                          name="featured_image"
                          className="form-control"
                          id="featured_image"
                          accept="image/*"
                          onChange={(e) => {}}
                        />
                      </div>
                    </CCol>
                    <CRow className="mt-5">
                      <CCol>
                        <div className="text-center">
                          <img
                            className="img-fluid max_img_view_admin"
                            alt="blog"
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label
                            htmlFor="featured_image"
                            className="fw-bold mb-2"
                          >
                            Bank Check Image
                          </label>
                          <input
                            type="file"
                            name="featured_image"
                            className="form-control"
                            id="featured_image"
                            accept="image/*"
                            onChange={(e) => {}}
                          />
                        </div>
                      </CCol>
                      <CCol>
                        <div className="text-center">
                          <img
                            className="img-fluid max_img_view_admin"
                            src={urlLink}
                            alt="blog"
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label
                            htmlFor="featured_image"
                            className="fw-bold mb-2"
                          >
                            CML Upload
                          </label>
                          <input
                            type="file"
                            name="featured_image"
                            className="form-control"
                            id="featured_image"
                            accept="image/*"
                            onChange={(e) => {}}
                          />
                        </div>
                      </CCol>
                    </CRow>
                  </CRow> */}
                </CRow>
                <CCol>
                  <div>
                    <CButton
                      type="submit"
                      className="btn btn-info text-white mt-3"
                      // onSubmit={(values)=>handleFormSubmit(values)}
                      disabled={totalInvestment == 100 ? false : true}
                    >
                      Submit
                    </CButton>
                  </div>
                </CCol>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};

export default UserManagement;
