import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import adminAxiosInstance from "../../config";
import { useContext } from "react";
import noteContext from "../context/NOTES/noteContext";

const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  try {
    if (token) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const ProtectedRoutes = ({ moduleType }) => {
  const a = useContext(noteContext)
  // console.log('a85521502', a)
  const [roledata, setRoleData] = useState([]);


  const isModuleTypeMatched = a?.find((item) => item?.Menu?.MenuTitle?.toLowerCase() === moduleType?.toLowerCase());
  // let roles = roledata?.Menu?.MenuTitle?.find(
  //     (ele) => 
  //     // console.log(ele,"savaniroledata") ,
  //     ele.toLowerCase() === moduleType.toLowerCase()
  //   );
    
    // console.log(isModuleTypeMatched,moduleType,"savaniroledata")
  // console.log(moduleType,"moduleTypemoduleTypemoduleType")
  const location = useLocation();
  const isAuth = isAuthenticated();

  // if (isModuleTypeMatched === undefined && null ) {
  //   return <Navigate to="/admin/login" />;
  // } else if (!roledata) {
  //   return <Outlet />;
  // } else {
  //   return isAuth && isModuleTypeMatched?.Menu?.MenuTitle ? (
  //     <Outlet />
  //   ) : (
  //     <Navigate to="/admin/dashboard" />
  //   );
  // }
  if (isModuleTypeMatched === undefined || isModuleTypeMatched === null) {
    return <Navigate to="/admin/dashboard" />;
} else if (!roledata) {
    return <Outlet />;
} else {
    return isAuth && isModuleTypeMatched?.Menu?.MenuTitle ? (
        <Outlet />
    ) : (
        <Navigate to="/admin/dashboard" />
    );
}

  // return isAuth  ?  (
  //   <Outlet />
  // ) : (
  //   <Navigate to="/admin/login" state={{ from: location }} />
  // );
};

export default ProtectedRoutes;
