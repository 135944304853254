import React, { useEffect, useState } from "react";
import backarrow from "../../assets/svg/darkback.svg";
import "./Employe.scss";
import { CCol, CRow } from "@coreui/react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import adminAxiosInstance from "../../../config";
import SweetAlert from "../../../helper/SweetAlert";

const EditEmployeemanage = () => {
    let { id } = useParams();
    // console.log(id,"qqqqwwwwwwwwwwwwwwwww")
    const adminToken = localStorage.getItem("token");

  const [roledata, setRoleData] = useState("");
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const [employeeData, setEmployeeData] = useState("");
  const [loading, setLoading] = useState(false);




  const handlesumbit = async (values)=>{
    // console.log(values,"788valueee")
  try {
  const response = await adminAxiosInstance.put(`/auth/update/${id}`, values, {
    headers: { Authorization: `Bearer ${adminToken}` },
  });
  SweetAlert({
    icon: "success",
    text: "Added Successfully!!",
    title: "Success",
    timer: 1500,
  });
  navigate("/admin/employeemanagement");

} catch (error) {
  // console.log(error?.response?.data?.message,"dhruv")
  SweetAlert({
    icon: "error",
    text: error?.response?.data?.message,
    title: "Warning!",
  });
}


  }

  const getbyidemployee = async () => {
    try {
      const response = await adminAxiosInstance.get(
        `/auth/byId/${id}`,
        {
          headers: { Authorization: `Bearer ${adminToken}` },
        }
      );
      // console.log("emplooyygrtbyidroleeeee===>", response?.data?.data);
      setEmployeeData(response.data.data);
    } catch (error) {
      // console.log(error.response.data.message);
    }
  };


  const fetchData = async () => {
    try {
      const response = await adminAxiosInstance.get(
        `/adminRole/getAll?page=1&limit=50`,
        {
          headers: { Authorization: `Bearer ${adminToken}` },
        }
      );
      // console.log("hhhhhhresponse===>", response?.data?.data?.result);
      setRoleData(response.data.data);
    } catch (error) {
      // console.log(error.response.data.message);
    }
  };

  const navigate = useNavigate();
  const Navigate = () => {
    navigate("/admin/employeemanagement");
  };



  useEffect(() => {
    getbyidemployee();
  fetchData();
  }, [])

  // console.log(employeeData,"employeeDataemployeeDataemployeeData")
  
  return (
    <div className="employeeadd employeemanage">
      <div className="bg-white p-4 rounded">
        <div className="d-flex  justify-content-between align-items-center">
          <h3 className="rounded mb-5">Update Admin Manage</h3>
          <button
            type="button"
            className="btn border-dark d-flex align-items-center gap-2 px-4"
            onClick={() => Navigate()}
          >
            <img src={backarrow} alt="" /> Back
          </button>
        </div>
        <div className="selectrole">
          
          <Formik
           enableReinitialize={true}
            initialValues={{
              fullName: employeeData?.fullName || '',
              mobile: employeeData?.mobile || '',
              email: employeeData?.email || '',
              password: employeeData?.password ||  '',
              confirmPassword: employeeData?.confirmPassword || '',
              roleByAdmin:employeeData?.roleByAdmin?.id || '',             
         
            }}
            onSubmit={handlesumbit}
          >
            {({  values,
                  errors,
                  handleChange,
                  isSubmitting,
                  handleBlur,
                  touched,
                  setFieldValue, }) => (
              <Form>
                {/* <label>
                  <Field type="checkbox" name="toggle" />
                  {`${values.toggle}`}
                </label>
                <label id="checkbox-group">Checked</label> */}

                <CRow className="mb-3 gy-3">
                  <CCol xs={12} md={6} lg={4}>
                  <div className="row">
                            <div className="col-12 mb-3">
                              <label
                                htmlFor="fullName"
                                className="form-label fw-label"
                              >
                                Full Name
                              </label>
                              <input
                                type="text"
                                id="fullName"
                                placeholder="fullName"
                                name="fullName"
                                value={values.fullName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                            </div>
                          </div>
                  </CCol>
                  <CCol xs={12} md={6} lg={4}>
                  <div className="row">
                            <div className="col-12 mb-3">
                              <label
                                htmlFor="email"
                                // className="form-label fw-label"
                              >
                               Email
                              </label>
                              <input
                                type="text"
                                id="email"
                                placeholder="email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                            </div>
                          </div>
                  </CCol>

                      <CCol xs={12} md={6} lg={4}>
                  <div className="form-group mb-3">
                      <label htmlFor="mobile">Mobile No</label>
                      <input
                        type="number"
                        // className="form-control"
                        id="mobile"
                        placeholder="Mobile"
                        name="mobile"
                        value={values.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`form-control ${
                          errors.mobile && touched.mobile
                            ? " is-invalid"
                            : ""
                        }`}
                      />
                     
                    </div>
                  </CCol>
               
                  <CCol xs={12} md={6} lg={4}>
                  <div className="form-group mb-3">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        
                        id="password"
                        placeholder="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        disabled
                        onBlur={handleBlur}
                        className={`form-control ${
                          errors.password && touched.password
                            ? " is-invalid"
                            : ""
                        }`}
                      />
                     
                    </div>
                  </CCol>
                  <CCol xs={12} md={6} lg={4}>
                  <div className="form-group mb-3">
                      <label htmlFor="password">Confirm Password</label>
                      <input
                        type="password"    
                        disabled                   
                        id="confirmPassword"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`form-control ${
                          errors.confirmPassword && touched.confirmPassword
                            ? " is-invalid"
                            : ""
                        }`}
                      />
                     
                    </div>
                  </CCol>
                  <CCol xs={12} md={6} lg={4}>
                    <label className="mb-2">Role*</label>
                    <Field as="select" name="roleByAdmin">


                     {roledata?.result?.map((item, i) => (
                                    <option key={i} value={item?._id}>
                                      {item?.RoleTitle}
                                    </option>
                                  ))}

                      {/* <option value="red">Red</option>
                      <option value="green">Green</option>
                      <option value="blue">Blue</option> */}
                    </Field>
                  </CCol>
                </CRow>
                <button type="submit" className="btn btn-info text-white mt-3">
                  Update Admin Manage
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditEmployeemanage;
