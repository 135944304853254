import React, { useEffect, useState } from "react";
import backarrow from "../../assets/svg/darkback.svg";
import "./Roles.scss";
import { CCol, CRow } from "@coreui/react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import CIcon from "@coreui/icons-react";
import { cibAddthis } from "@coreui/icons";
import adminAxiosInstance from "../../../config";
import SweetAlert from "../../../helper/SweetAlert";

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
const adminToken = localStorage.getItem("token");

const EditRoles = () => {
  let { id } = useParams();
  // console.log(id, "idididididid");

  const navigate = useNavigate();

  const [roleData, setRoleData] = useState("");
  const [menudata, setMenudata] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);


  const getbyidrole = async () => {
    try {
      const response = await adminAxiosInstance.get(`/adminRole/byId/${id}`, {
        headers: { Authorization: `Bearer ${adminToken}` },
      });
      setRoleData(response?.data?.data);
      // console.log("grtbyidroleeeee===>", response?.data?.data);
    } catch (error) {
      // console.log(error.response.data.message);
    }
  };
  const Navigate = () => {
    navigate("/admin/roles");
  };

  // console.log(roleData, "rppppppppppppp");
  const handlesumbit = async (values) => {
    const filteredAccess = values.Access.filter((accessItem) => {
      return accessItem.MenuId && accessItem.MenuTitle;
    });
  
    // Create a modified values object with the filtered Access array
    const modifiedValues = { ...values, Access: filteredAccess };


    // const formattedAccess = values.Access.map(item => {
    //   return {
    //     Menu: {
    //       MenuId: item.MenuId,
    //       MenuTitle: item.MenuTitle
    //     },
    //     Read: item.Read,
    //     Edit: item.Edit,
    //     Delete: item.Delete
    //   };
    // });

    const formattedAccess = values.Access.map(item => {
      if (item.MenuId === null || item.MenuTitle === null) {
        return null; // Return null if either MenuId or MenuTitle is null
      }
    
      return {
        Menu: {
          MenuId: item.MenuId,
          MenuTitle: item.MenuTitle,
          To: item.To,
        },
        Read: item.Read,
        Edit: item.Edit,
        Delete: item.Delete
      };
    });

    

    const formattedModifiedValues = {
      RoleTitle: modifiedValues.RoleTitle,
      Access: formattedAccess
    };

        try {
      const response = await adminAxiosInstance.put(`/adminRole/update/${id}`, formattedModifiedValues, {
        headers: { Authorization: `Bearer ${adminToken}` },
      });
      SweetAlert({
        icon: "success",
        text: "Added Successfully!!",
        title: "Success",
        timer: 1500,
      });
      navigate("/admin/roles");
    } catch (error) {
      // console.log(error?.response?.data?.message, "dhruv");
      SweetAlert({
        icon: "error",
        text: error?.response?.data?.message,
        title: "Warning!",
      });
    }
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await adminAxiosInstance.get(
        `/menuAccess/getAll?page=1&limit=50`,
        {
          headers: { Authorization: `Bearer ${adminToken}` },
        }
      );
      setMenudata(response?.data?.data);
      // console.log("ssssresponse===>", response?.data?.data?.result);
    setLoading(false);

    } catch (error) {
    setLoading(false);
    }
  };

  useEffect(() => {
    getbyidrole();
    fetchData();
  }, []);
  // console.log(roleData, "roledatamenudatamenudata");

 

  return (
    <>
      <div className="userroleadd rolepagetitle">
        <h3 className="d-flex align-items-center gap-2">
          <CIcon icon={cibAddthis} customClassName="nav-icon" />
          Edit Admin Role 
        </h3>
        <div className="bg-white mt-4 p-4 rounded">
          <div className="d-flex  justify-content-between align-items-center">
            <h3 className="rounded mb-0">Edit Admin Role</h3>
            <button
              type="button"
              className="btn border-info d-flex align-items-center gap-2 px-4"
              onClick={() => Navigate()}
            >
              <img src={backarrow} alt="" /> Back
            </button>
          </div>
          <div className="selectrole">
            <Formik
              enableReinitialize={true}
              initialValues={{
                RoleTitle: roleData?.RoleTitle || "",                
                Access:
                  menudata?.result?.map((item, index) => ({
                    MenuId: roleData?.Access?.[index]?.Menu?.MenuId ||  null,
                    MenuTitle: roleData?.Access?.[index]?.Menu?.MenuTitle || null,
                    To: roleData?.Access?.[index]?.Menu?.To || null,
                    Read: roleData?.Access?.[index]?.Read || false,
                    Edit: roleData?.Access?.[index]?.Edit || false,
                    Delete: roleData?.Access?.[index]?.Delete || false,
                  })) || [],
                
              }}
              onSubmit={handlesumbit}
            >
              {({
                values,
                errors,
                handleChange,
                isSubmitting,
                handleBlur,
                touched,
                setFieldValue,
              }) => (
                <Form>
                  {/* {console.log(values, "valuesvaluesvalues")} */}

                 
                  <div className="d-flex flex-column roletitleback my-4">
                  
                    <div className="col-12 mb-3">
                      <label
                        htmlFor="RoleTitle"
                        className="form-label fw-label"
                      >
                        Role Title
                      </label>
                      <input
                        type="text"
                        id="RoleTitle"
                        placeholder="Role Title"
                        name="RoleTitle"
                        value={values.RoleTitle}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                      />
                    </div>

                  
                  </div>
                  {loading ? (<div className="loader-content">
                <div className="spinner-border text-secondary"></div>
              </div>):
              <>
              {menudata?.result?.map((item, index) => (
                <CRow className="mb-3">
                  <CCol xs lg={5}>
                    <input
                      type="checkbox"
                      name={`Access[${index}]?.Menu`}
                      className="me-2"
                      value={item?.Menu}
                      checked={values.Access[index]?.MenuId !== null}

                      // checked={roleData?.Access?.[index]?.Menu?.MenuId || values.Access[index]?.Menu  }
                   
                       onChange={(e) => {
                        const isChecked = e.target.checked;                            
                        setFieldValue(`Access[${index}].MenuId`, isChecked ? item?._id : null);
                        setFieldValue(`Access[${index}].MenuTitle`, isChecked ? item?.MenuTitle : null);
                        setFieldValue(`Access[${index}].To`, isChecked ? item?.To : null);
                      }}

                    />
                    <label>{item?.MenuTitle}</label>
                  
                  </CCol>
                  <CCol xs lg={7}>
                    <div
                      role="group"
                      aria-labelledby="checkbox-group"
                     
                      className={`d-flex gap-5 ${
                        isEditing && values.Access[index]?.Menu
                          ? ""
                          : "disabled" 
                      }`}
                    >
                    
                      <label>
                        <Field
                          type="checkbox"
                          name={`Access[${index}].Read`}
                          className="me-2"
                          checked={values.Access[index]?.Read || false} // Set to false if undefined
                          onChange={(e) => {
                            // Toggle the value of Read when the checkbox is clicked
                            setFieldValue(`Access[${index}].Read`, !values.Access[index]?.Read);
                          }}
                        />
                        Read
                      </label>
                      <label>
                        <Field
                          type="checkbox"
                          name={`Access[${index}].Edit`}
                          className="me-2"
                          checked={values.Access[index]?.Edit || false} // Set to false if undefined
                          onChange={(e) => {
                            // Toggle the value of Read when the checkbox is clicked
                            setFieldValue(`Access[${index}].Edit`, !values.Access[index]?.Edit);
                          }}
                          
                          // checked={
                          //   (roleData.Access &&
                          //     roleData.Access[index] &&
                          //     roleData.Access[index].Edit) }
                          //     onChange={(e) => {
                          //       setFieldValue(`Access[${index}].Edit`, e.target.checked);
                               
                          //     }}
                        />
                        Edit
                      </label>
                      <label>
                        <Field
                          type="checkbox"
                          name={`Access[${index}].Delete`}
                          className="me-2"                             
                          checked={values.Access[index]?.Delete || false} // Set to false if undefined
                          onChange={(e) => {
                            // Toggle the value of Read when the checkbox is clicked
                            setFieldValue(`Access[${index}].Delete`, !values.Access[index]?.Delete);
                          }}
                        />
                        Delete
                      </label>
                    </div>
                  </CCol>
                </CRow>
              ))}
              
              </>
              }


                  <button
                    type="submit"
                    className="btn btn-info text-white mt-3"
                  >
                    Edit permissions
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRoles;
