import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  mobile: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
  fullName: Yup.string().required("Name is required "),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  // .min(10, "number must be 10 digit")
  // .max(10, "number must be 10 digit"),
  // password: Yup.string().required("Password is required"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  // )
});
export const AdminLoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),

  // .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
});
export const cpLoginSchema = Yup.object().shape({
  mobile: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
});
export const SignUpSchema = Yup.object().shape({
  fullName: Yup.string()
    // .max(30, "Must be 15 characters or less")
    .required("Name is required "),
  mobile: Yup.string()
    .required("number  is required")
    // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field ")
    .min(7, "number must be min 7 digit")
    .max(15, "number must be max 15 digit"),
  // .matches(/^-?d*.?d*$/, "String value is not required."),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  state: Yup.string()
    .required("state is required")
    .matches(/^[aA-zZ\s]+$/, "Only charector are allowed for this field "),
  country: Yup.string()
    .required("country is required")
    .matches(/^[aA-zZ\s]+$/, "Only charector are allowed for this field "),
  city: Yup.string()
    .required("city is required")
    .matches(/^[aA-zZ\s]+$/, "Only charector are allowed for this field "),
  password: Yup.string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  //   confirmPassword: Yup.string()
  //     .required()
  //     .oneOf([Yup.ref("password"), null], "Passwords must match"),
  //   .matches(
  //     "^(?=.*[A-Za-z])(?=.*d)[A-Za-zd]{8,}$",
  //     "Minimum eight characters, at least one letter and one number"
  //   ),
});

export const userSignUpSchema = Yup.object().shape({
  fullName: Yup.string()
    // .max(30, "Must be 15 characters or less")
    .required("Name is required "),
  mobile: Yup.string()
    .required("number  is required")
    // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field ")
    .min(7, "number must be min 7 digit")
    .max(15, "number must be max 15 digit"),
  // .matches(/^-?d*.?d*$/, "String value is not required."),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  state: Yup.string()
    .required("state is required")
    .matches(/^[aA-zZ\s]+$/, "Only charector are allowed for this field "),
  country: Yup.string()
    .required("country is required")
    .matches(/^[aA-zZ\s]+$/, "Only charector are allowed for this field "),
  city: Yup.string()
    .required("city is required")
    .matches(/^[aA-zZ\s]+$/, "Only charector are allowed for this field "),
});

export const partnerSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required "),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobile: Yup.string()
    .required("number  is required")
    // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field ")
    .min(7, "number must be min 7 digit")
    .max(15, "number must be max 15 digit"),
  // .max(10, "number must be 10 digit"),
});

export const addCompanySchema = Yup.object().shape({
  name: Yup.string().required("Name is required "),
  urlName: Yup.string().required("URL Name is required "),
  totalAmount: Yup.number().required("Price is required "),
  minInvestment: Yup.number().required("minInvestment is required "),
  // .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
  // region: Yup.string().required("Region is required "),
  sector: Yup.string().required("Sector is required "),
  status: Yup.string().required("Status is required "),
  isPortfolio: Yup.string().required("In Portfolio is required "),
  // portfolioType: Yup.string().required("In Portfolio Type is required "),
  // portfolioQuantity: Yup.string().required(
  //   "In Portfolio Quantity is required "
  // ),
  top: Yup.number().required("Top is required "),
  remainingPeriod: Yup.date().required("Remaining date is required "),
  isinCode: Yup.string().required("ISIN Code is required "),
});

export const careerSchema = Yup.object().shape({
  fullName: Yup.string().required("Name is required "),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobile: Yup.string()
    .required("number  is required")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
  // .min(10, "number must be 10 digit")
  // .max(10, "number must be 10 digit"),
  position: Yup.string().required("Position is required "),
  resume: Yup.string().required("Resume is required "),
});
export const addUserSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required "),
  lastName: Yup.string().required("Last Name is required "),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobile: Yup.string()
    .required("Mobile number  is required")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field ")
    .min(7, "number must be min 7 digit")
    .max(15, "number must be max 15 digit"),
  address: Yup.string().required("Address is required "),
  gender: Yup.string().required("Gender is required "),
  nationality: Yup.string().required("Nationality is required "),
  dateOfBirth: Yup.date().required("Date is required!"),
  userState: Yup.string().required("Active is required "),
  employementStatus: Yup.string().required("Employement Status is required!"),
  wealthSource: Yup.string().required("Wealth Source is required!"),
  // howHearAboutUs: Yup.string().required("HowHear About Us is required!"),
});
export const addRealEstateSchema = Yup.object().shape({
  name: Yup.string().required("Name is required!"),
  urlName: Yup.string().required("Url Name is required!"),
  totalAmount: Yup.number().required("Price is required"),
  minimunInvestment: Yup.number().required("Min Investment is required"),
  // averageRentalYield: Yup.number().required("Avg. Rental Yield is required"),
  sharePrice: Yup.number().required("Share Price is required"),
  remainingPeriod: Yup.date().required("Remaining Period is required"),
  propertyType: Yup.string().required("Property Type is required"),
  status: Yup.string().required("Status is required"),
});
export const addDocument = Yup.object().shape({
  customerId: Yup.string().required("Customer Id is required"),
  id: Yup.string().required("Id is required"),
});
export const addCPSchema = Yup.object().shape({
  fullName: Yup.string().required("Name is required "),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobile: Yup.string()
    .required("Mobile number  is required")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field ")
    .min(7, "number must be min 7 digit")
    .max(15, "number must be max 15 digit"),
});
export const addStaffSchema = Yup.object().shape({
  fullName: Yup.string().required("Name is required "),
  mobile: Yup.string()
    .required("Mobile number  is required")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field ")
    .min(7, "number must be min 7 digit")
    .max(15, "number must be max 15 digit"),
  employeeCode: Yup.string().required("Employee Code is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email Code is required"),
  featured_image: Yup.string().required("Featured Image Code is required"),
});
export const addExternalStockSchema = Yup.object().shape({
  companyName: Yup.string().required("Company Name is required "),
  sector: Yup.string().required("Sector is required "),
  price: Yup.string().required("Price is required "),
  quantity: Yup.string().required("Quantity is required "),
  stockDescription: Yup.string().required("External Stock is required "),
});

export const addBlogManagementSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  urlTitle: Yup.string().required("URL Name is required!"),
  // tags: Yup.string().required("Tags is required!"),
  // date: Yup.date().required("Date is required!"),
  ogTitle: Yup.string().required("ogTitle is required!"),
  ogType: Yup.string().required("ogType is required!"),
  ogImage: Yup.string().required("ogImage is required!"),
  ogUrl: Yup.string().required("ogUrl is required!"),
  // featured_image: Yup.string().required("Image is required!"),
  ogDescription: Yup.string().required("ogDescription is required!"),
  metaTitle: Yup.string().required("Meta Title is required!"),
  metaDescription: Yup.string().required("Meta Description Title is required!"),
  metaKeywords: Yup.string().required("Meta Keywords Title is required!"),
  metaRobots: Yup.string().required(" Meta Robots is required!"),
  canonicalTag: Yup.string().required("Canonical Tag is required!"),
  content: Yup.string().required("Post Content is required!"),
});
export const addinthenewsSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  link: Yup.string().required("Link is required!"),
  description: Yup.string().required("Description is required!"),
});
export const addCpManagementSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required!"),
  email: Yup.string()
    .email("Invalid Email Address")
    .required("Email is required!"),
  mobile: Yup.number().required("Mobile is required!"),
});
